export const RegisterUser = (data) => ({
  type: 'REGISTER',
  payload: {
    client: 'default',
    request: {
      url: '/user/register',
      method: 'post',
      data,
    },
  },
});

export const RegisterChangeError = (error_register) => ({
  type: 'REGISTER_ERROR',
  payload: {
    error_register,
  },
});

export const VerifyAcount = (email, code) => ({
  type: 'VERIFY_ACOUNT',
  payload: {
    client: 'pay_verify',
    request: {
      url: '/verify_count',
      method: 'post',
      data: {
        email,
        code,
      },
    },
  },
});

export const ChangeStatusActiveError = (boolean) => ({
  type: 'CHANGE_ACTIVE_ERROR',
  payload: {
    boolean,
  },
});

export const UpdateEmailRegister = (email) => ({
  type: 'EMAIL_REGISTER',
  payload: {
    email,
  },
});
