import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { set_location } from '../../redux/actions/General';
import { get_list_services } from '../../redux/actions/Shop';

import src from '../../media/2328999.jpg';

class Services extends Component {
  static propTypes = {};

  reinsert_position = () => {
    const { set_location } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          set_location(latitude, longitude);
        },
        error => console.log(error)
      );
    } else {
    }
  };

  componentDidMount = () => {
    const { get_list_services } = this.props;
    get_list_services();
  };
  render() {
    const {
      services = [],
      my_lat,
      my_lon,
      services_types = [],
      generalReducer
    } = this.props;

    if ((!my_lat || my_lat === '') && (!my_lon || my_lon === '')) {
      this.reinsert_position();
    }

    return (
      <Grid textAlign="center" verticalAlign="middle" stackable columns={4}>
        <Grid.Row style={{ maxWidth: 1050 }}>
          {services.map(({ service_name }) => {
            console.log(services_types, 'ZZZ');
            return (
              <Grid.Column>
                <NavLink to={`/list-sevice/${service_name}`}>
                  <div
                    className="cards-services"
                    style={{ backgroundImage: `url(${src})` }}
                  >
                    {services_types[service_name]}
                  </div>
                </NavLink>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  generalReducer: { my_lat, my_lon, services_types },
  generalReducer,
  shopReducer: { services }
}) => ({
  my_lat,
  my_lon,
  services,
  services_types,
  generalReducer
});

const mapDispatchToProps = {
  set_location,
  get_list_services
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Services)
);
