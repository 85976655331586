import React, { Component } from 'react';
import {
  Grid,
  Header,
  Checkbox,
  Divider,
  Input,
  Button,
  Modal,
  Image,
  Responsive
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  get_page_info,
  update_page,
  active_page,
  deactivate_page
} from '../../redux/actions/Profile';

const templates = [
  {
    template_number: 1,
    url:
      'https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_500/v1580796753/container-assets/Captura_de_Pantalla_2020-02-03_a_la_s_17.38.51.png',
    title: 'Super SEO'
  }
];

let timeOut;

const style_sheets_list = [
  {
    color_number: 1,
    color: 'red'
  },
  {
    color_number: 2,
    color: 'orange'
  },
  {
    color_number: 3,
    color: 'yellow'
  },
  {
    color_number: 4,
    color: 'olive'
  },
  {
    color_number: 5,
    color: 'green'
  },
  {
    color_number: 6,
    color: 'teal'
  },
  {
    color_number: 7,
    color: 'violet',
    sub_color: '#0000ff8c'
  }
];

class Profile extends Component {
  setProps = () => ({
    accept_card_active: this.props.accept_card_active === 1 ? true : false,
    active: this.props.active === 1 ? true : false,
    active_days: this.props.active_days === 1 ? true : false,
    cover_image: this.props.cover_image ? this.props.cover_image : '',
    description: this.props.description ? this.props.description : '',
    domain: this.props.domain ? this.props.domain : '',
    images_days: this.props.images_days === 1 ? true : false,
    logo: this.props.logo ? this.props.logo : '',
    offers_active: this.props.offers_active === 1 ? true : false,
    shop_id: this.props.shop_id ? this.props.shop_id : null,
    shop_name: this.props.shop_name ? this.props.shop_name : '',
    style_sheets: this.props.style_sheets ? this.props.style_sheets : 1,
    subdomain: this.props.subdomain ? this.props.subdomain : '',
    subdomain_valid: this.props.subdomain ? this.props.subdomain : '',
    template_type: this.props.template_type ? this.props.template_type : 1,
    pages_id: this.props.pages_id,
    template_modal: false,
    style_modal: false,
    type_charge: this.props.type_charge
  });

  state = this.setProps();

  componentDidMount = () => {
    const {
      get_page_info,
      token,
      match: {
        params: { shop_id }
      }
    } = this.props;

    get_page_info(token, shop_id).then(response => {
      if (response.type === 'GET_PAGE_INFO_SUCCESS') {
        this.setState(this.setProps());
      }
    });
  };

  update_state_change = e => {
    const { update_page, token, active_page, deactivate_page } = this.props;
    const { shop_id, pages_id } = this.state;

    if (e.name === 'active') {
      if (e.checked) {
        active_page(shop_id, pages_id, token).then(response => {
          if (response.type === 'ACTIVE_PAGE_SUCCESS') {
            this.setState({
              [e.name]: e.checked
            });
          }
        });
      } else {
        deactivate_page(shop_id, pages_id, token).then(response => {
          if (response.type === 'DEACTIVE_PAGE_SUCCESS') {
            this.setState({
              [e.name]: e.checked
            });
          }
        });
      }
      return;
    }

    const date_id = new Date();
    this.setState({
      [e.name]: e.checked
    });

    const data = {
      [e.name]: e.checked ? 1 : 0
    };
    update_page(data, shop_id, pages_id, token).then(response => {
      if (response.type === 'UPDATE_PAGE_SUCCESS') {
        setTimeout(() => {
          this.setState({
            id_diferent: date_id.getTime()
          });
        }, 100);
      }
    });
  };

  update_template = number => {
    const { token, update_page } = this.props;
    const { shop_id, pages_id } = this.state;

    const date_id = new Date();

    this.setState(
      {
        template_type: number,
        template_modal: false
      },
      () => {
        update_page(
          {
            template_type: number
          },
          shop_id,
          pages_id,
          token
        ).then(response => {
          if (response.type === 'UPDATE_PAGE_SUCCESS') {
            setTimeout(() => {
              this.setState({
                id_diferent: date_id.getTime()
              });
            }, 100);
          }
        });
      }
    );
  };

  update_style = number => {
    const { token, update_page } = this.props;
    const { shop_id, pages_id } = this.state;

    const date_id = new Date();

    this.setState(
      {
        style_sheets: number,
        style_modal: false
      },
      () => {
        update_page(
          {
            style_sheets: number
          },
          shop_id,
          pages_id,
          token
        ).then(response => {
          if (response.type === 'UPDATE_PAGE_SUCCESS') {
            setTimeout(() => {
              this.setState({
                id_diferent: date_id.getTime()
              });
            }, 100);
          }
        });
      }
    );
  };

  update_subdomain = e => {
    const { token, update_page } = this.props;
    const { shop_id, pages_id } = this.state;

    console.log(this.state);

    const date_id = new Date();

    const subdomain = e.target.value;
    this.setState({
      subdomain
    });

    clearTimeout(timeOut);

    timeOut = setTimeout(() => {
      update_page(
        {
          subdomain
        },
        shop_id,
        pages_id,
        token
      ).then(response => {
        if (response.type === 'UPDATE_PAGE_SUCCESS') {
          setTimeout(() => {
            this.setState({
              id_diferent: date_id.getTime(),
              error_subdomain: '',
              subdomain_valid: subdomain
            });
          }, 100);
        } else {
          this.setState({
            error_subdomain: 'El subdominio ya esta en uso'
          });
        }
      });
    }, 500);
  };

  render() {
    const {
      accept_card_active,
      active,
      active_days,
      domain,
      images_days,
      offers_active,
      shop_name,
      style_sheets,
      subdomain,
      subdomain_valid,
      template_type,
      template_modal,
      style_modal,
      id_diferent,
      error_subdomain,
      type_charge
    } = this.state;

    console.log(this.state);

    return (
      <div
        style={{
          height: document.documentElement.clientHeight - 65,
          margin: 0
        }}
      >
        <Responsive {...Responsive.onlyMobile}>
          {type_charge === 'PREMIUN' ? (
            <Grid celled style={{ height: '100%', margin: 0 }}>
              <Grid.Column
                style={{
                  paddingLeft: '0rem',
                  paddingRight: '0rem',
                  overflowY: 'auto',
                  height: 'inherit',
                  width: '100%'
                }}
              >
                <Grid.Row>
                  <Header
                    as="h3"
                    content={shop_name}
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Input
                    label=".kimvex.com"
                    size="mini"
                    labelPosition="right"
                    placeholder="MiPagina"
                    value={subdomain}
                    onChange={this.update_subdomain}
                  />
                  <label>{error_subdomain}</label>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Activar pagina"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      toggle
                      name="active"
                      checked={active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        template_modal: true
                      })
                    }
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: '#15bfc2',
                      color: '#15bfc2'
                    }}
                  >
                    Seleccionar diseño
                  </Button>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        style_modal: true
                      })
                    }
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: '#15bfc2',
                      color: '#15bfc2'
                    }}
                  >
                    Seleccionar color
                  </Button>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Activar sección de dias de servicio"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="active_days"
                      toggle
                      checked={active_days}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de tarjetas"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="accept_card_active"
                      toggle
                      checked={accept_card_active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de ofertas"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="offers_active"
                      toggle
                      checked={offers_active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de galeria de imagenes"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="images_days"
                      toggle
                      checked={images_days}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          ) : (
            <div>El plan contratado no incluye pagina web</div>
          )}
        </Responsive>
        <Responsive style={{ height: '100%' }} {...Responsive.onlyTablet}>
          {type_charge === 'PREMIUN' ? (
            <Grid celled style={{ height: '100%', margin: 0 }}>
              <Grid.Column
                style={{
                  paddingLeft: '0rem',
                  paddingRight: '0rem',
                  overflowY: 'auto',
                  height: 'inherit',
                  width: '30%'
                }}
              >
                <Grid.Row>
                  <Header
                    as="h3"
                    content={shop_name}
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Input
                    label=".kimvex.com"
                    size="mini"
                    labelPosition="right"
                    placeholder="MiPagina"
                    value={subdomain}
                    onChange={this.update_subdomain}
                  />
                  <label>{error_subdomain}</label>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Activar pagina"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      toggle
                      name="active"
                      checked={active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        template_modal: true
                      })
                    }
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: '#15bfc2',
                      color: '#15bfc2'
                    }}
                  >
                    Seleccionar diseño
                  </Button>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        style_modal: true
                      })
                    }
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: '#15bfc2',
                      color: '#15bfc2'
                    }}
                  >
                    Seleccionar color
                  </Button>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Activar sección de dias de servicio"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="active_days"
                      toggle
                      checked={active_days}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de tarjetas"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="accept_card_active"
                      toggle
                      checked={accept_card_active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de ofertas"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="offers_active"
                      toggle
                      checked={offers_active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de galeria de imagenes"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="images_days"
                      toggle
                      checked={images_days}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column style={{ width: '70%', padding: '0' }}>
                {active ? (
                  <iframe
                    key={id_diferent}
                    src={`https://${subdomain_valid}.kimvex.com`}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 0
                    }}
                  ></iframe>
                ) : (
                  <div style={{ width: '100%', height: '100%', border: 0 }}>
                    <Header
                      as="h5"
                      content="La pagina esta inactiva"
                      inverted
                      style={{
                        textAlign: 'center',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        color: '#15bfc2',
                        fontFamily: 'Montserrat'
                      }}
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid>
          ) : (
            <div>El plan contratado no incluye pagina web</div>
          )}
        </Responsive>
        <Responsive style={{ height: '100%' }} {...Responsive.onlyComputer}>
          {type_charge === 'PREMIUN' ? (
            <Grid celled style={{ height: '100%', margin: 0 }}>
              <Grid.Column
                width={3}
                style={{
                  paddingLeft: '0rem',
                  paddingRight: '0rem',
                  overflowY: 'auto',
                  height: 'inherit'
                }}
              >
                <Grid.Row>
                  <Header
                    as="h3"
                    content={shop_name}
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <Input
                    label=".kimvex.com"
                    size="mini"
                    labelPosition="right"
                    placeholder="MiPagina"
                    value={subdomain}
                    onChange={this.update_subdomain}
                  />
                  <label>{error_subdomain}</label>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Activar pagina"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      toggle
                      name="active"
                      checked={active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        template_modal: true
                      })
                    }
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: '#15bfc2',
                      color: '#15bfc2'
                    }}
                  >
                    Seleccionar diseño
                  </Button>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() =>
                      this.setState({
                        style_modal: true
                      })
                    }
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: '#15bfc2',
                      color: '#15bfc2'
                    }}
                  >
                    Seleccionar color
                  </Button>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Activar sección de dias de servicio"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="active_days"
                      toggle
                      checked={active_days}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de tarjetas"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="accept_card_active"
                      toggle
                      checked={accept_card_active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de ofertas"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="offers_active"
                      toggle
                      checked={offers_active}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
                <Divider
                  style={{ width: '100%', margin: '1rem 0rem' }}
                ></Divider>
                <Grid.Row>
                  <Header
                    as="h5"
                    content="Actvar sección de galeria de imagenes"
                    inverted
                    style={{
                      textAlign: 'center',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <div style={{ margin: '0.5rem 0rem', textAlign: 'center' }}>
                    <Checkbox
                      name="images_days"
                      toggle
                      checked={images_days}
                      onChange={(e, data) => this.update_state_change(data)}
                    />
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={13} style={{ padding: '0' }}>
                {active ? (
                  <iframe
                    key={id_diferent}
                    src={`https://${subdomain}.kimvex.com`}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 0
                    }}
                  ></iframe>
                ) : (
                  <div style={{ width: '100%', height: '100%', border: 0 }}>
                    <Header
                      as="h5"
                      content="La pagina esta inactiva"
                      inverted
                      style={{
                        textAlign: 'center',
                        fontWeight: 'normal',
                        marginBottom: 0,
                        color: '#15bfc2',
                        fontFamily: 'Montserrat'
                      }}
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid>
          ) : (
            <div>El plan contratado no incluye pagina web</div>
          )}
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <Modal
            onClose={() => this.setState({ template_modal: false })}
            closeOnDimmerClick
            open={template_modal}
            centered={false}
            style={{
              width: '100%'
            }}
          >
            <Modal.Header>Seleccione el diseño de la pagina</Modal.Header>
            <Modal.Content image>
              <Modal.Description style={{ width: '100%' }}>
                <Grid centered style={{ width: '100%' }}>
                  {templates.map(({ template_number, url, title }) => (
                    <Grid.Column
                      style={{ width: '60%' }}
                      textAlign="center"
                      key={template_number}
                    >
                      <Header as="h4">{title}</Header>
                      <p>
                        <Image width="150px" src={url} ui={false} />
                      </p>
                      <Button
                        onClick={() => this.update_template(template_number)}
                        color={
                          template_number !== template_type ? 'yellow' : 'teal'
                        }
                      >
                        {template_number !== template_type
                          ? 'Seleccionar'
                          : 'Seleccionado'}
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Responsive>
        <Responsive style={{ height: '100%' }} {...Responsive.onlyTablet}>
          <Modal
            onClose={() => this.setState({ template_modal: false })}
            closeOnDimmerClick
            open={template_modal}
            centered={false}
            style={{
              width: '90%'
            }}
          >
            <Modal.Header>Seleccione el diseño de la pagina</Modal.Header>
            <Modal.Content image>
              <Modal.Description style={{ width: '100%' }}>
                <Grid centered style={{ width: '100%' }}>
                  {templates.map(({ template_number, url, title }) => (
                    <Grid.Column
                      style={{ width: '20%' }}
                      textAlign="center"
                      key={template_number}
                    >
                      <Header as="h4">{title}</Header>
                      <p>
                        <Image width="120px" src={url} ui={false} />
                      </p>
                      <Button
                        onClick={() => this.update_template(template_number)}
                        color={
                          template_number !== template_type ? 'yellow' : 'teal'
                        }
                      >
                        {template_number !== template_type
                          ? 'Seleccionar'
                          : 'Seleccionado'}
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Responsive>
        <Responsive style={{ height: '100%' }} {...Responsive.onlyComputer}>
          <Modal
            onClose={() => this.setState({ template_modal: false })}
            closeOnDimmerClick
            open={template_modal}
            centered={false}
            style={{
              width: '60%'
            }}
          >
            <Modal.Header>Seleccione el diseño de la pagina</Modal.Header>
            <Modal.Content image>
              <Modal.Description style={{ width: '100%' }}>
                <Grid centered style={{ width: '100%' }}>
                  {templates.map(({ template_number, url, title }) => (
                    <Grid.Column
                      style={{ width: '20%' }}
                      textAlign="center"
                      key={template_number}
                    >
                      <Header as="h4">{title}</Header>
                      <p>
                        <Image width="120px" src={url} ui={false} />
                      </p>
                      <Button
                        onClick={() => this.update_template(template_number)}
                        color={
                          template_number !== template_type ? 'yellow' : 'teal'
                        }
                      >
                        {template_number !== template_type
                          ? 'Seleccionar'
                          : 'Seleccionado'}
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <Modal
            onClose={() => this.setState({ style_modal: false })}
            open={style_modal}
            centered={false}
            style={{
              width: '100%'
            }}
          >
            <Modal.Header>Seleccione color del diseño</Modal.Header>
            <Modal.Content image>
              <Modal.Description style={{ width: '100%' }}>
                <Grid centered style={{ width: '100%' }}>
                  {style_sheets_list.map(
                    ({ color_number, color, sub_color }) => (
                      <Grid.Column
                        style={{ width: '60%' }}
                        textAlign="center"
                        kye={color_number}
                      >
                        <div
                          style={{
                            height: '100px',
                            background: sub_color ? sub_color : color,
                            margin: '0.5rem 0rem'
                          }}
                        ></div>
                        <Button
                          onClick={() => this.update_style(color_number)}
                          size="mini"
                          color={color}
                        >
                          {color_number === style_sheets
                            ? 'Selecionado'
                            : 'Selecionar'}
                        </Button>
                      </Grid.Column>
                    )
                  )}
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Responsive>
        <Responsive style={{ height: '100%' }} {...Responsive.onlyTablet}>
          <Modal
            onClose={() => this.setState({ style_modal: false })}
            open={style_modal}
            centered={false}
            style={{
              width: '90%'
            }}
          >
            <Modal.Header>Seleccione color del diseño</Modal.Header>
            <Modal.Content image>
              <Modal.Description style={{ width: '100%' }}>
                <Grid divided style={{ width: '100%' }}>
                  {style_sheets_list.map(
                    ({ color_number, color, sub_color }) => (
                      <Grid.Column
                        style={{ width: '20%' }}
                        textAlign="center"
                        kye={color_number}
                      >
                        <div
                          style={{
                            height: '100px',
                            background: sub_color ? sub_color : color,
                            margin: '0.5rem 0rem'
                          }}
                        ></div>
                        <Button
                          onClick={() => this.update_style(color_number)}
                          size="mini"
                          color={color}
                        >
                          {color_number === style_sheets
                            ? 'Selecionado'
                            : 'Selecionar'}
                        </Button>
                      </Grid.Column>
                    )
                  )}
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Responsive>
        <Responsive style={{ height: '100%' }} {...Responsive.onlyComputer}>
          <Modal
            onClose={() => this.setState({ style_modal: false })}
            open={style_modal}
            centered={false}
            style={{
              width: '60%'
            }}
          >
            <Modal.Header>Seleccione color del diseño</Modal.Header>
            <Modal.Content image>
              <Modal.Description style={{ width: '100%' }}>
                <Grid centered style={{ width: '100%' }}>
                  {style_sheets_list.map(
                    ({ color_number, color, sub_color }) => (
                      <Grid.Column
                        style={{ width: '20%' }}
                        textAlign="center"
                        kye={color_number}
                      >
                        <div
                          style={{
                            height: '100px',
                            background: sub_color ? sub_color : color,
                            margin: '0.5rem 0rem'
                          }}
                        ></div>
                        <Button
                          onClick={() => this.update_style(color_number)}
                          size="mini"
                          color={color}
                        >
                          {color_number === style_sheets
                            ? 'Selecionado'
                            : 'Selecionar'}
                        </Button>
                      </Grid.Column>
                    )
                  )}
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Responsive>
      </div>
    );
  }
}

const mapStateToprops = ({
  loginReducer: { token },
  profileReducer: {
    accept_card_active,
    active,
    active_days,
    cover_image,
    description,
    domain,
    images_days,
    logo,
    offers_active,
    shop_id,
    shop_name,
    style_sheets,
    subdomain,
    template_type,
    pages_id,
    type_charge
  }
}) => ({
  token,
  accept_card_active,
  active,
  active_days,
  cover_image,
  description,
  domain,
  images_days,
  logo,
  offers_active,
  shop_id,
  shop_name,
  style_sheets,
  subdomain,
  template_type,
  pages_id,
  type_charge
});

const mapDisptachToProps = {
  get_page_info,
  update_page,
  active_page,
  deactivate_page
};

export default withRouter(
  connect(mapStateToprops, mapDisptachToProps)(Profile)
);
