export const update_statuss = () => ({
  type: 'UPDATE_STATUS',
  payload: {},
});

export const upload_cover = (data, token) => ({
  type: 'UPLOAD_IMAGE_COVER',
  payload: {
    client: 'default',
    request: {
      url: '/images/shop/cover',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data',
        token,
      },
      data,
    },
  },
});

export const upload_logo = (data, token) => ({
  type: 'UPLOAD_IMAGE_LOGO',
  payload: {
    client: 'default',
    request: {
      url: '/images/shop/logo',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        token,
      },
      data,
    },
  },
});

export const upload_images = (data, token) => ({
  type: 'UPLOAD_IMAGES',
  payload: {
    client: 'default',
    request: {
      url: '/images/shop',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data',
        token,
      },
      data,
    },
  },
});

export const save_shop = (token, data, shop_id) => ({
  type: 'SAVE_PROPERTY',
  payload: {
    client: 'default',
    request: {
      url: shop_id ? `/shop/${shop_id}/update` : '/shop',
      method: shop_id ? 'put' : 'post',
      headers: {
        token,
      },
      data,
    },
  },
});

export const get_shop = (shop_id) => ({
  type: 'GET_PROPERTY',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}`,
      method: 'get',
      headers: {
        token: 'mission_all',
      },
    },
  },
});

export const reset_state_shop = () => ({
  type: 'RESET_STATES_SHOP',
  payload: {},
});

export const delete_images_server = (image_url, shop_id, token) => ({
  type: 'DELETE_IMAGES',
  payload: {
    client: 'default',
    request: {
      url: `/shops/${shop_id}/image`,
      method: 'delete',
      headers: {
        token,
      },
      data: {
        url_image: image_url,
      },
    },
  },
});

export const get_list_shops_location = (
  lat,
  lon,
  category,
  limit = 10,
  last_shop_id = 0,
  minDistance = 0.0
) => ({
  type: 'GET_SHOPS_LOCATION',
  payload: {
    client: 'default',
    request: {
      url: `/find/shops/${lat}/${lon}`,
      method: 'get',
      headers: {
        token: 'mission_all',
      },
      params: {
        limit,
        last_shop_id,
        minDistance,
        category,
      },
    },
  },
});

export const set_empty_shops_nearby = () => ({
  type: 'SET_EMPTY_SHOPS_EMPTY',
  payload: {},
});

export const get_list_services = () => ({
  type: 'GET_SERVICES',
  payload: {
    client: 'default',
    request: {
      url: '/services',
      method: 'get',
    },
  },
});

export const get_comments = (shop_id, page = 0, limit = 5) => ({
  type: 'GET_COMMENTS_SHOP',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/comments?limit=${limit}&page=${page}`,
      method: 'get',
      headers: {
        token: 'mission_all',
      },
    },
  },
});

export const set_comments_empty = () => ({
  type: 'SET_COMMENTS_EMPTY',
  payload: {},
});

export const post_comment = (token, shop_id, comment) => ({
  type: 'COMMENT_SHOP',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/comment`,
      method: 'post',
      headers: {
        token,
      },
      data: {
        comment,
      },
    },
  },
});

export const rating_shop_event = (rating, token, shop_id) => ({
  type: 'SET_RATING',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/score`,
      method: 'post',
      headers: {
        token,
      },
      data: {
        score: rating,
      },
    },
  },
});

export const get_my_score = (token, shop_id, user_id) => {
  console.log(user_id, 'eeeeee');
  return {
    type: 'GET_MY_SET_SCORE',
    payload: {
      client: 'default',
      request: {
        url: `/shop/${shop_id}/score/${user_id}`,
        method: 'get',
        headers: {
          token: token,
        },
      },
    },
  };
};

export const update_rating_shop = (token, score, shop_id, user_id) => ({
  type: 'UPDATE_RATING',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/score/${user_id}`,
      method: 'put',
      headers: {
        token,
      },
      data: {
        score,
      },
    },
  },
});

export const disable_shop = (shop_id, token) => ({
  type: 'DISABLE_SHOP',
  payload: {
    client: 'default',
    request: {
      url: `/shop/lock/${shop_id}`,
      method: 'put',
      headers: {
        token,
      },
    },
  },
});

export const unlock_shop = (shop_id, token) => ({
  type: 'UNLOCK_SHOP',
  payload: {
    client: 'default',
    request: {
      url: `/shop/unlock/${shop_id}`,
      method: 'put',
      headers: {
        token,
      },
    },
  },
});

export const get_sub_services = (service_id) => ({
  type: 'GET_SUB_SERVICES',
  payload: {
    client: 'default',
    request: {
      url: `/sub_service/${service_id}`,
      method: 'get',
    },
  },
});
