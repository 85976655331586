import React, { Component } from 'react';
import { Grid, Label, Segment, Header, Divider, Icon } from 'semantic-ui-react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ContentShop extends Component {
  static propTypes = {};

  render() {
    const {
      description,
      LUN,
      MAR,
      MIE,
      JUE,
      VIE,
      SAB,
      DOM,
      accept_card = '0',
      list_cards = [],
      lat,
      lon,
    } = this.props;
    console.log(
      accept_card,
      list_cards,
      accept_card && list_cards,
      accept_card == '1'
    );
    return (
      <Grid stackable columns={2}>
        <Grid.Column computer="10">
          <Segment>
            <Header
              as="h2"
              content="Información"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat',
              }}
            />
            <Divider />
            {description}
            {/* Somos una clinica especializada en el tratamiento de la piel. Con 10
            años en el mercado y nuestros clientes nos respaldan al haberles
            dado un servicio de primera calidad. Con nosotros tendras seguro el
            tratamiento que mas desees */}
            <Header
              as="h2"
              content="Horarios de atención"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat',
              }}
            />
            <Divider />
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              L
              <Label.Detail>
                {LUN === 'CERRADO' || LUN === 'CLOSE' ? 'CERRADO' : LUN}
              </Label.Detail>
            </Label>
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              M
              <Label.Detail>
                {MAR === 'CERRADO' || MAR === 'CLOSE' ? 'CERRADO' : MAR}
              </Label.Detail>
            </Label>
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              MI
              <Label.Detail>
                {MIE === 'CERRADO' || MIE === 'CLOSE' ? 'CERRADO' : MIE}
              </Label.Detail>
            </Label>
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              J
              <Label.Detail>
                {JUE === 'CERRADO' || JUE === 'CLOSE' ? 'CERRADO' : JUE}
              </Label.Detail>
            </Label>
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              V
              <Label.Detail>
                {VIE === 'CERRADO' || VIE === 'CLOSE' ? 'CERRADO' : VIE}
              </Label.Detail>
            </Label>
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              S
              <Label.Detail>
                {SAB === 'CERRADO' || SAB === 'CLOSE' ? 'CERRADO' : SAB}
              </Label.Detail>
            </Label>
            <Label as="a" color="teal" style={{ marginTop: '0.5rem' }}>
              D
              <Label.Detail>
                {DOM === 'CERRADO' || DOM === 'CLOSE' ? 'CERRADO' : DOM}
              </Label.Detail>
            </Label>
            <Header
              as="h2"
              content="Acepta tarjetas"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat',
              }}
            />
            <Divider />
            {accept_card == '1' &&
              list_cards.map((i, k) => {
                return (
                  <Label
                    style={{ marginTop: '0.5rem' }}
                    as="a"
                    color="teal"
                    image
                    key={k}
                  >
                    <Icon name="credit card" />
                    {i}
                  </Label>
                );
              })}
          </Segment>
        </Grid.Column>
        <Grid.Column computer="6">
          <Segment>
            <Header
              as="h2"
              content="Ubicacion"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat',
              }}
            />
            <Divider />
            <Map
              style={{ width: '100%', height: '300px' }}
              center={{ lat, lng: lon }}
              zoom={17}
              dragging={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={{ lat, lng: lon }} />
            </Map>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  shopReducer: {
    description,
    LUN,
    MAR,
    MIE,
    JUE,
    VIE,
    SAB,
    DOM,
    accept_card,
    list_cards,
    lat,
    lon,
  },
}) => ({
  description,
  LUN,
  MAR,
  MIE,
  JUE,
  VIE,
  SAB,
  DOM,
  accept_card,
  list_cards,
  lat,
  lon,
});

export default withRouter(connect(mapStateToProps)(ContentShop));
