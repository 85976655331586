import React, { Component } from 'react';
import {
  Segment,
  Header,
  Divider,
  Button,
  Comment,
  Form,
  Rating,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
//America/Mexico_City
import {
  post_comment,
  get_comments,
  rating_shop_event,
  get_my_score,
  update_rating_shop,
  set_comments_empty,
} from '../../redux/actions/Shop';

class Comments extends Component {
  state = {
    comment: '',
    error_comment: false,
    page: 0,
    limit: 5,
  };

  set_comment = () => {
    const {
      match: {
        params: { shop_id },
      },
      token,
      post_comment,
      get_comments,
    } = this.props;

    const { comment } = this.state;
    if (comment.trim()) {
      post_comment(token, shop_id, comment).then((response) => {
        if (response.type === 'COMMENT_SHOP_SUCCESS') {
          get_comments(shop_id);
          this.setState({
            comment: '',
          });
        }
      });
    }
  };

  update_comment_text = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  rating_shop = ({ rating }) => {
    const {
      rating_shop_event,
      token,
      match: {
        params: { shop_id },
      },
      my_score_set,
      update_rating_shop,
      user_id,
      get_my_score,
    } = this.props;
    if (token && my_score_set > 0) {
      update_rating_shop(token, rating, shop_id, user_id).then((response) => {
        if (response.type === 'UPDATE_RATING_SUCCESS') {
          get_my_score(token, shop_id, user_id);
        }
      });
    } else {
      rating_shop_event(rating, token, shop_id).then((response) => {
        if (response.type === 'SET_RATING_SUCCESS') {
          get_my_score(token, shop_id, user_id);
        }
      });
    }
  };

  more_comments = () => {
    const {
      match: {
        params: { shop_id },
      },
      get_comments,
    } = this.props;
    const { limit } = this.state;
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        get_comments(shop_id, this.state.page, limit);
      }
    );
  };

  componentWillMount = () => {
    const {
      match: {
        params: { shop_id },
      },
      get_comments,
      set_comments_empty,
      token,
      user_id,
      get_my_score,
    } = this.props;
    const { page, limit } = this.state;

    set_comments_empty();
    get_comments(shop_id, page, limit).then((res) => {
      if (user_id) {
        if (res.type === 'GET_COMMENTS_SHOP_SUCCESS') {
          get_my_score(token, shop_id, user_id);
        }
      }
    });
  };

  validate_browser = () => {
    let browser = 'unknown';
    let ua = navigator.userAgent;
    if ((ua.indexOf('Opera') || ua.indexOf('OPR')) != -1) {
      browser = 'Opera';
    } else if (ua.indexOf('Edge') != -1) {
      browser = 'Edge';
    } else if (ua.indexOf('Chrome') != -1) {
      browser = 'Chrome';
    } else if (ua.indexOf('Safari') != -1) {
      browser = 'Safari';
    } else if (ua.indexOf('Firefox') != -1) {
      browser = 'Firefox';
    } else if (ua.indexOf('MSIE') != -1 || !!document.documentMode == true) {
      //IF IE > 10
      browser = 'IE';
    } else {
      browser = 'unknown';
    }
    return browser;
  };

  render() {
    const { comments = [], my_score_set, token } = this.props;
    const { comment: comment_text } = this.state;

    console.log(my_score_set, comments);
    return (
      <Segment>
        <Header
          as="h3"
          content="Califica"
          inverted
          style={{
            textAlign: 'left',
            fontWeight: 'normal',
            marginBottom: 0,
            color: '#15bfc2',
            fontFamily: 'Montserrat',
          }}
        />
        <Rating
          className="left floated"
          icon="star"
          rating={my_score_set ? my_score_set : 0}
          maxRating={5}
          size="massive"
          onRate={(event, data) => this.rating_shop(data)}
        />
        <Header
          as="h2"
          content="Comentarios"
          inverted
          style={{
            textAlign: 'left',
            fontWeight: 'normal',
            marginBottom: 0,
            color: '#15bfc2',
            fontFamily: 'Montserrat',
          }}
        />
        <Divider />
        <Comment.Group size="small" style={{ margin: 'auto' }}>
          {comments.map(({ fullname, image, comment, create_date_at }, key) => {
            const first_time = moment.tz(
              create_date_at.replace(/-/g, '/'),
              'Atlantic/Azores'
            );
            console.log(first_time);
            if (image) {
              const split_img_avatar = image.split('/upload/');
              image = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_50/${split_img_avatar[1]}`;
            }
            return (
              <Comment key={key}>
                <Comment.Avatar
                  as="a"
                  src={
                    image
                      ? image
                      : 'https://react.semantic-ui.com/images/avatar/small/christian.jpg'
                  }
                />
                <Comment.Content>
                  <Comment.Author>{fullname}</Comment.Author>
                  <Comment.Metadata>
                    <div>
                      {this.validate_browser() == 'Safari'
                        ? create_date_at.slice(0, 9)
                        : first_time
                            .clone()
                            .tz('America/Mexico_City')
                            .fromNow()}
                    </div>
                  </Comment.Metadata>
                  <Comment.Text>{comment}</Comment.Text>
                </Comment.Content>
              </Comment>
            );
          })}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <span
              onClick={this.more_comments}
              style={{ color: 'teal', cursor: 'pointer' }}
            >
              Mostrar más...
            </span>
          </div>
          {token ? (
            <Form reply>
              <Form.TextArea
                value={comment_text}
                onChange={this.update_comment_text}
              />
              <Button
                content="Comentar"
                color="teal"
                labelPosition="left"
                icon="edit"
                onClick={this.set_comment}
              />
            </Form>
          ) : (
            <NavLink style={{ color: 'teal' }} to="/login">
              Iniciar sersion para comentar >
            </NavLink>
          )}
        </Comment.Group>
      </Segment>
    );
  }
}

const mapStateToProps = ({
  loginReducer: { token },
  shopReducer: { comments, my_score_set },
  profileReducer: { user_id },
}) => ({
  comments,
  token,
  my_score_set,
  user_id,
});

const mapDispatchToProps = {
  post_comment,
  get_comments,
  rating_shop_event,
  get_my_score,
  update_rating_shop,
  set_comments_empty,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comments)
);
