import React, { Component } from 'react';
import {
  Segment,
  Header,
  Divider,
  Grid,
  Button,
  Image,
} from 'semantic-ui-react';
import ReactBnbGallery from 'react-bnb-gallery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Gallery extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      galleryOpened: false,
    };
    this.toggleGallery = this.toggleGallery.bind(this);
  }

  toggleGallery() {
    this.setState((prevState) => ({
      galleryOpened: !prevState.galleryOpened,
    }));
  }

  static propTypes = {};

  state = { activeIndex: 1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    let { list_images = [] } = this.props;
    console.log(list_images, typeof list_images);
    list_images = list_images
      .map((img) => {
        if (img) {
          const url_split = img.split('/upload/');
          img = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${url_split[1]}`;
        }
        return img;
      })
      .filter((img) => img !== undefined);
    console.log(list_images, 'las imagenes');
    return (
      <Segment>
        <Header
          as="h2"
          content="Galeria"
          inverted
          style={{
            textAlign: 'left',
            fontWeight: 'normal',
            marginBottom: 0,
            color: '#15bfc2',
            fontFamily: 'Montserrat',
          }}
        />
        <Divider />
        <Grid style={{ marginBottom: '0.5rem' }} doubling columns={5}>
          {console.log(list_images)}
          {list_images.length > 0 &&
            list_images.map((img, k) => (
              <Grid.Column key={k}>
                <Image src={img} />
              </Grid.Column>
            ))}
        </Grid>
        <Button onClick={this.toggleGallery}>Ver más</Button>
        <ReactBnbGallery
          phrases={{
            hidePhotoList: 'Ocultar',
            showPhotoList: 'Mostrar',
          }}
          show={this.state.galleryOpened}
          photos={list_images.map((img) => img)}
          onClose={this.toggleGallery}
        />
      </Segment>
    );
  }
}

const mapStateToProps = ({ shopReducer: { list_images } }) => ({ list_images });

export default withRouter(connect(mapStateToProps, null)(Gallery));
