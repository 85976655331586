import React, { Component } from 'react';
import { Card, Image, Rating, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  get_list_shops_location,
  set_empty_shops_nearby
} from '../../redux/actions/Shop';

class ListServices extends Component {
  static propTypes = {};

  state = {
    list_shop_location: [],
    last_distance: 0.0,
    last_shop_id: 0,
    new_find: true
  };

  get_shops = () => {
    const {
      my_lat,
      my_lon,
      get_list_shops_location,
      match: {
        params: { service }
      }
    } = this.props;
    const { last_shop_id, last_distance } = this.state;

    get_list_shops_location(
      my_lat,
      my_lon,
      service,
      4,
      last_shop_id,
      last_distance
    ).then(res => {
      if (res.type === 'GET_SHOPS_LOCATION_SUCCESS') {
        const {
          data: { last_shop_id, last_distance, shop = [] }
        } = res.payload;

        if (last_shop_id && last_distance) {
          this.setState({
            last_shop_id,
            last_distance,
            list_shop_location: this.state.list_shop_location.concat(shop),
            new_find: false
          });
        }
      }
    });
  };

  componentWillMount = () => {
    this.get_shops();
  };

  cards_sevices = type => {
    const { list_shop_location = [] } = this.state;
    const { services_types, sub_service_types } = this.props;
    return (
      <Card.Group style={{ margin: '0rem' }} centered>
        {list_shop_location.map(
          (
            {
              sub_service_name,
              shop_id,
              shop_name,
              address,
              phone,
              score_shop,
              cover_image,
              service_name,
              distance = 0
            },
            key
          ) => {
            let measure = 'km';

            if (distance < 1000) {
              measure = 'm';
            }

            if (distance > 1000) {
              measure = 'km';
              distance = distance.toFixed(0) / 1000;
            }

            const split_img = cover_image.split('/upload/');
            let image_standar = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_300,h_300/${split_img[1]}`;
            return (
              <Card
                style={{
                  width:
                    type == 'computer'
                      ? '250px'
                      : type == 'tablet'
                      ? '45%'
                      : '85%'
                }}
                as={Link}
                to={`/shop/${shop_id}`}
                key={key}
              >
                <Image
                  style={{
                    width:
                      type == 'computer'
                        ? '250px'
                        : type == 'tablet'
                        ? '100%'
                        : '100%',
                    height: '170px !important'
                  }}
                  src={image_standar}
                  wrapped
                  ui={false}
                />
                <Card.Content>
                  <Card.Header
                    style={type == 'mobile' ? { fontSize: '2rem' } : {}}
                    className="text-color-kimvex"
                  >
                    {shop_name}
                  </Card.Header>
                  <Card.Description
                    style={type == 'mobile' ? { fontSize: '1.4rem' } : {}}
                  >
                    {address}
                  </Card.Description>
                  <Card.Meta
                    style={type == 'mobile' ? { fontSize: '1.3rem' } : {}}
                  >
                    {services_types[service_name]}{' '}
                    {sub_service_types[sub_service_name]}
                  </Card.Meta>
                </Card.Content>
                <Card.Content
                  style={type == 'mobile' ? { fontSize: '1.3rem' } : {}}
                  extra
                >
                  {score_shop > 0 ? (
                    <Rating
                      className="left floated"
                      disabled
                      icon="star"
                      defaultRating={score_shop}
                      maxRating={5}
                    />
                  ) : (
                    'Sin calificación'
                  )}
                  <span className="right floated text-color-kimvex">
                    {distance.toFixed(2)} {measure}
                  </span>
                </Card.Content>
              </Card>
            );
          }
        )}
      </Card.Group>
    );
  };

  render() {
    console.log(this.state);

    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.cards_sevices('mobile')}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.cards_sevices('tablet')}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.cards_sevices('computer')}
        </Responsive>

        <div
          style={{ width: '100%', textAlign: 'center', padding: '2rem 0rem' }}
        >
          <span
            onClick={this.get_shops}
            style={{ color: 'teal', cursor: 'pointer', fontSize: '1.2rem' }}
          >
            Mostrar más...
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  shopReducer: { list_shop_location, last_shop_id, last_distance },
  generalReducer: { my_lat, my_lon, services_types, sub_service_types }
}) => ({
  list_shop_location,
  my_lat,
  my_lon,
  last_shop_id,
  last_distance,
  services_types,
  sub_service_types
});

const mapDispatchToProps = {
  get_list_shops_location,
  set_empty_shops_nearby
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListServices)
);
