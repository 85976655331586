import React, { Component } from 'react';
import {
  Input,
  Card,
  Form,
  Grid,
  Button,
  Segment,
  Header,
  Divider,
  Message,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  UpdateProfile,
  update_status,
  GetProfile,
  update_password,
  upload_profile,
  change_image_profile
} from '../../redux/actions/Profile';

const options = [
  { key: 'm', text: 'Hombre', value: 'MALE' },
  { key: 'f', text: 'Mujer', value: 'FEMALE' }
];

class Information extends Component {
  state = {
    image_profile: '',
    image_profile_preview: '',
    name: this.props.fullname,
    phone_state: this.props.phone,
    age_state: this.props.age.slice(0, 10),
    gender_state: this.props.gender,
    password1: '',
    password2: '',
    current_password: '',
    password_diferent: false,
    success_password_update: false,
    error_update_image: false,
    success_update_image: false,
    text_error: '',
    loader_profile_image: false
  };

  handleInputValues = (e, type) => {
    switch (type) {
      case 'name':
        this.setState({ name: e.target.value, edition_information: true });
        break;
      case 'email':
        this.setState({
          email: e.target.value.trim()
        });
        break;
      case 'phone':
        this.setState({
          phone_state: e.target.value.trim()
        });
        break;
      case 'birthday':
        this.setState({
          age_state: e.target.value.trim()
        });
        break;
      case 'gender':
        this.setState({ gender_state: e.value, edition_information: true });
        break;
      default:
        break;
    }
  };

  update_flags = (error, text) => {
    if (error) {
      this.setState({
        error_update_image: true,
        text_error: text
      });

      setTimeout(() => {
        this.setState({
          error_update_image: false,
          text_error: text
        });
      }, 5000);
    } else {
      this.setState({
        success_update_image: true
      });

      setTimeout(() => {
        this.setState({
          success_update_image: false
        });
      }, 5000);
    }
  };

  change_state_image_profile = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          image_profile: file,
          image_profile_preview: reader.result
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.update_flags(true, 'Error al obtener la imagen');
    }
  };

  submit_image_profile = () => {
    const {
      upload_profile,
      token,
      change_image_profile,
      GetProfile
    } = this.props;
    if (this.state.image_profile) {
      const formData = new FormData();

      formData.append('file', this.state.image_profile);
      this.setState({
        loader_profile_image: true
      });
      upload_profile(formData, token).then(
        ({
          payload: {
            data: { result }
          }
        }) => {
          change_image_profile(result, token).then(r => {
            GetProfile(token);
            this.update_flags(false, 'Se actualizo la imagen de perfil');
            this.setState({
              loader_profile_image: false,
              image_profile: ''
            });
          });
        }
      );
    } else {
      this.update_flags(true, 'No se ha seleccionado imagen');
      this.setState({
        loader_profile_image: false,
        image_profile: ''
      });
    }
  };

  submit_infor = () => {
    const {
      UpdateProfile,
      update_status,
      token,
      success_update,
      fail_update,
      GetProfile
    } = this.props;
    const {
      name: fullname,
      phone_state: phone,
      age_state: age,
      gender_state: gender
    } = this.state;

    const info_validate = {};

    if (fullname) {
      info_validate.fullname = fullname;
    }
    if (phone) {
      info_validate.phone = phone;
    }
    if (age) {
      info_validate.age = age;
    }
    if (gender) {
      info_validate.gender = gender;
    }

    UpdateProfile(token, info_validate).then(r => GetProfile(token));

    setInterval(() => {
      if (success_update || fail_update) update_status();
    }, 5000);
  };

  updatePassword = (e, type) => {
    switch (type) {
      case 'pass1':
        this.setState({
          password1: e.target.value
        });
        break;
      case 'pass2':
        this.setState({
          password2: e.target.value
        });
        break;
      case 'current':
        this.setState({
          current_password: e.target.value
        });
        break;

      default:
        break;
    }
  };

  submit_change_password = () => {
    const { password1, password2, current_password } = this.state;
    const {
      update_password,
      update_status,
      fail_update_password,
      token
    } = this.props;

    if (password1 === password2) {
      update_password(password1, current_password, token).then(r => {
        this.setState({ success_password_update: true });

        setTimeout(() => {
          this.setState({ success_password_update: false });
        }, 5000);
      });
      setTimeout(() => {
        if (fail_update_password) {
          update_status();
        }
      }, 5000);
    } else {
      this.setState({
        password_diferent: true
      });

      setTimeout(() => {
        this.setState({
          password_diferent: false
        });
      }, 5000);
    }
  };

  render() {
    let {
      image,
      success_update,
      fail_update,
      fail_update_password
    } = this.props;
    const {
      image_profile_preview,
      image_profile,
      name,
      phone_state,
      age_state,
      gender_state,
      success_password_update,
      password_diferent,
      error_update_image,
      success_update_image,
      text_error,
      loader_profile_image
    } = this.state;

    const image_split = image.split('/upload/');
    image = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${image_split[1]}`;

    return (
      <Segment>
        <Header
          as="h2"
          content="Datos de mi perfil"
          inverted
          style={{
            textAlign: 'left',
            fontWeight: 'normal',
            marginBottom: 0,
            color: '#15bfc2',
            fontFamily: 'Montserrat'
          }}
        />
        <Divider />
        <Grid stackable centered columns={2}>
          <Grid.Column computer="4">
            <Card
              style={{ margin: 'auto' }}
              image={
                image_profile_preview
                  ? image_profile_preview
                  : image
                  ? image
                  : 'https://react.semantic-ui.com/images/wireframe/image.png'
              }
              extra={
                <div>
                  <Input
                    fluid
                    type="file"
                    onChange={this.change_state_image_profile}
                  />
                  <Button
                    onClick={this.submit_image_profile}
                    style={{ marginTop: '0.2rem' }}
                    color="teal"
                    fluid
                    size="large"
                  >
                    Cambiar imagen
                  </Button>
                  {image_profile && (
                    <Button
                      style={{ marginTop: '0.2rem' }}
                      color="gray"
                      fluid
                      size="large"
                      onClick={() =>
                        this.setState({
                          image_profile: '',
                          image_profile_preview: ''
                        })
                      }
                    >
                      Cancelar
                    </Button>
                  )}
                </div>
              }
            />
            {error_update_image && <Message error header={text_error} />}
            {loader_profile_image && (
              <Dimmer active>
                <Loader content="Loading" />
              </Dimmer>
            )}
            {success_update_image && (
              <Message positive header="La imagen de perfil se actualizo" />
            )}
          </Grid.Column>
          <Grid.Column computer="6">
            <Segment stacked>
              <Form size="small">
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Nombre"
                  onChange={e => this.handleInputValues(e, 'name')}
                  value={name}
                />
                <Form.Input
                  fluid
                  icon="phone"
                  iconPosition="left"
                  placeholder="phone"
                  type="phone"
                  onChange={e => this.handleInputValues(e, 'phone')}
                  value={phone_state}
                />
                <Form.Input
                  required
                  fluid
                  icon="birthday cake"
                  iconPosition="left"
                  placeholder="Fecha de nacimiento"
                  type="date"
                  onChange={e => this.handleInputValues(e, 'birthday')}
                  value={age_state}
                />
                <Form.Select
                  value={gender_state}
                  fluid
                  options={options}
                  onChange={(e, data) => this.handleInputValues(data, 'gender')}
                  placeholder="Genero"
                />
                <Button
                  onClick={this.submit_infor}
                  color="teal"
                  fluid
                  size="large"
                >
                  Actualizar información
                </Button>
              </Form>
              {success_update && (
                <Message
                  positive
                  header="Información actualizada"
                  content="Tu información ha sido actualizada."
                />
              )}

              {fail_update && (
                <Message
                  error
                  header="Falló al actualizar"
                  content="Ocurrio un problema al actualizar tus datos."
                />
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column computer="4">
            <Segment stacked>
              <Form size="small">
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Contraseña actual"
                  type="password"
                  onChange={e => this.updatePassword(e, 'current')}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Nueva contraseña"
                  type="password"
                  onChange={e => this.updatePassword(e, 'pass1')}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Repetir contraseña"
                  type="password"
                  onChange={e => this.updatePassword(e, 'pass2')}
                />
                <Button
                  onClick={this.submit_change_password}
                  color="teal"
                  fluid
                  size="large"
                >
                  Cambiar contraseña
                </Button>
              </Form>
              {success_password_update && (
                <Message
                  positive
                  header="Contraseña actualizada"
                  content="Tu contraseña ha sido actualizada."
                />
              )}

              {fail_update_password && (
                <Message
                  error
                  header="Error al actualizar tu contraseña"
                  content="Tu contraseña no pudo ser actualizada, revisa que estes agregando correctamente la contraseña actual."
                />
              )}

              {password_diferent && (
                <Message
                  error
                  header="Las contraseñas son diferentes"
                  content="Verifica que las contraseñas sean iguales."
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = ({
  loginReducer: { token },
  profileReducer: {
    fullname,
    email,
    phone,
    age,
    gender,
    image,
    create_at,
    success_update,
    fail_update,
    fail_update_password
  }
}) => ({
  fullname,
  email,
  phone,
  age,
  gender,
  image,
  create_at,
  success_update,
  fail_update,
  token,
  fail_update_password
});

const mapDispatchToProps = {
  UpdateProfile,
  update_status,
  GetProfile,
  update_password,
  upload_profile,
  change_image_profile
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Information)
);
