import React, { Component } from 'react';
import {
  Container,
  Segment,
  Step,
  Icon,
  Grid,
  Header,
  Divider,
  Button,
  Form,
  Input,
  Modal,
  Loader,
  Dimmer,
  Responsive
} from 'semantic-ui-react';
import { Number, Cvc, Expiration } from 'react-credit-card-primitives';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import OpenPay from 'openpay-js';
import { pay_shop } from '../../redux/actions/Pay';
import { auth_code } from '../../redux/actions/Profile';

require('openpay-js/lib/openpay-data.v1');

const { OpenPay: OpenPayGetDevice } = document;

OpenPay.setId('mjrw070vhk0mauhlh2un');
OpenPay.setApiKey('pk_99fa998dab4544fcba16fd44253f6f86');
OpenPay.setSandboxMode(true);

OpenPayGetDevice.setId('mjrw070vhk0mauhlh2un');
OpenPayGetDevice.setApiKey('pk_99fa998dab4544fcba16fd44253f6f86');
OpenPayGetDevice.setSandboxMode(true);

const plan_list = {
  BASIC: {
    month: 6,
    present: 1,
    total: 7,
    total_refered: 8,
    description: 'Compra paquete BASIC en kimvex.'
  },
  ELIT: {
    month: 9,
    present: 1,
    total: 10,
    total_refered: 11,
    description: 'Compra paquete ELIT en kimvex.'
  },
  PREMIUN: {
    month: 12,
    present: 2,
    total: 14,
    total_refered: 15,
    description: 'Compra paquete PREMIUN en kimvex.'
  }
};

class Pay extends Component {
  state = {
    step: 1,
    modalOpen: false,
    plan: '',
    price: '',
    expiration_month: 12,
    expiration_year: 2020,
    postal_code: '',
    card_number: '',
    card_name: '',
    card_street: '',
    card_neighborhood: '',
    card_city: '',
    card_state: '',
    cvv: '',
    card_valid: false,
    date_valid: true,
    cvv_valid: false,
    load_card: false,
    response_information: {},
    succes_checkout: false,
    device_session_id: '',
    success_pay: false,
    fail_pay: false,
    referrals_id: null,
    pending_pay: false
  };

  changeStep = step =>
    this.setState({
      step
    });

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => {
    this.setState({ modalOpen: false });
    if (this.state.success_pay) {
      window.location = '/profile';
    }
  };

  planSelect = plan => {
    this.setState({
      plan: plan
    });
  };

  validate_on_openpay = () => {
    const {
      card_number,
      card_name,
      expiration_month,
      expiration_year,
      postal_code,
      card_street,
      card_neighborhood,
      card_city,
      card_state,
      cvv,
      card_valid,
      date_valid,
      cvv_valid
    } = this.state;
    if (
      card_valid &&
      card_name &&
      date_valid &&
      postal_code &&
      card_street &&
      card_neighborhood &&
      card_city &&
      card_state &&
      cvv_valid
    ) {
      this.setState({
        load_card: true
      });
      OpenPay.token.create(
        {
          card_number: card_number,
          holder_name: card_name,
          expiration_year: parseInt(expiration_year.toString().slice(2, 4)),
          expiration_month: expiration_month,
          cvv2: cvv,
          address: {
            city: card_city,
            country_code: 'MX',
            postal_code: postal_code,
            line1: card_street,
            line2: card_neighborhood,
            line3: '',
            state: card_state
          }
        },
        result => {
          this.setState({
            load_card: false,
            response_information: result.data,
            step: 3
          });
        },
        error => {
          alert('FALLO AL MOMENTO DE VALIDAR TARJETA');

          this.setState({
            load_card: false
          });
        }
      );
    }
  };

  update_number_card = ({ value, valid }) => {
    this.setState({
      card_valid: valid,
      card_number: value
    });
  };

  update_expire_date = ({ month, year, valid }) => {
    this.setState({
      expiration_month: month,
      expiration_year: year,
      date_valid: valid
    });
  };

  update_cvv = ({ value, valid }) => {
    this.setState({
      cvv: value,
      cvv_valid: valid
    });
  };

  update_name_card = e => {
    this.setState({
      card_name: e.target.value
    });
  };

  update_postal_code = e => {
    this.setState({
      postal_code: e.target.value
    });
  };

  update_street = e => {
    this.setState({
      card_street: e.target.value
    });
  };

  update_neighborhood = e => {
    this.setState({
      card_neighborhood: e.target.value
    });
  };

  update_city = e => {
    this.setState({
      card_city: e.target.value
    });
  };

  update_state = e => {
    this.setState({
      card_state: e.target.value
    });
  };

  pay_services = () => {
    const {
      pay_shop,
      match: {
        params: { shop_id }
      },
      user_id,
      fullname,
      phone,
      email
    } = this.props;
    const {
      plan,
      price,
      device_session_id,
      response_information: { id },
      referrals_id
    } = this.state;

    this.setState({
      pending_pay: true
    });

    document.getElementById('html-hidden').classList.add('remove-overflow');

    pay_shop({
      pay_data: {
        source_id: id,
        method: 'card',
        amount: price,
        currency: 'MXN',
        description: plan_list[plan].description,
        order_id: uuidv4(),
        device_session_id: device_session_id,
        customer: {
          name: fullname,
          last_name: '',
          phone_number: phone,
          email: email
        }
      },
      user_id: user_id,
      shop_id: shop_id,
      plan: plan,
      referrals_id: referrals_id
    }).then(result => {
      if (result.type === 'PAY_SERVICE_SUCCESS') {
        this.setState({
          modalOpen: true,
          success_pay: true,
          pending_pay: false,
          fail_pay: false
        });
      }

      if (result.type === 'PAY_SERVICE_FAIL') {
        this.setState({
          modalOpen: true,
          fail_pay: true,
          pending_pay: false,
          success_pay: false
        });
      }

      document
        .getElementById('html-hidden')
        .classList.remove('remove-overflow');
    });
  };

  validate_code = e => {
    const { auth_code, token } = this.props;
    console.log('-.-', e);
    if (e.target.value.length === 19) {
      auth_code(e.target.value, token).then(result => {
        if (result.type === 'AUTH_CODE_SUCCESS') {
          const {
            data: {
              validate: { code_reference_id, code }
            }
          } = result.payload;
          if (code_reference_id && code) {
            this.setState({
              referrals_id: code_reference_id
            });
          }
        } else {
          this.setState({
            referrals_id: null
          });
        }
      });
    } else {
      this.setState({
        referrals_id: null
      });
    }
  };

  componentDidMount = () => {
    this.setState({
      device_session_id: OpenPayGetDevice.deviceData.setup()
    });
  };

  render() {
    const {
      expiration_year,
      expiration_month,
      postal_code,
      card_number,
      card_name,
      card_street,
      card_neighborhood,
      card_city,
      card_state,
      cvv,
      load_card,
      plan,
      succes_checkout,
      success_pay,
      fail_pay,
      referrals_id,
      pending_pay,
      price
    } = this.state;
    console.log(this.state);
    return (
      <Container>
        {pending_pay && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: '#fdf4f400'
            }}
          >
            <Dimmer active>
              <Loader active>Pagando, espere un momento...</Loader>
            </Dimmer>
          </div>
        )}

        <Responsive {...Responsive.onlyMobile}>
          <Button.Group
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '0.5rem !important'
            }}
          >
            <Button size="mini" basic active onClick={() => this.changeStep(1)}>
              Planes
            </Button>
            <Button
              size="mini"
              basic
              disabled={plan ? false : true}
              active={this.state.step === 2}
              onClick={() => this.changeStep(2)}
            >
              Datos
            </Button>
            <Button
              size="mini"
              basic
              disabled={succes_checkout ? false : true}
              active={this.state.step === 3}
              onClick={() => this.changeStep(3)}
            >
              Pago
            </Button>
          </Button.Group>
        </Responsive>

        <Responsive {...Responsive.onlyTablet}>
          <Step.Group disabled attached="top">
            <Step
              active={this.state.step === 1}
              onClick={() => this.changeStep(1)}
            >
              <Icon name="shopping basket" />
              <Step.Content>
                <Step.Title>Planes</Step.Title>
                <Step.Description>Elige un plan</Step.Description>
              </Step.Content>
            </Step>

            <Step
              disabled={plan ? false : true}
              active={this.state.step === 2}
              onClick={() => this.changeStep(2)}
            >
              <Icon name="payment" />
              <Step.Content>
                <Step.Title>Datos</Step.Title>
                <Step.Description>Ingresar datos de compra</Step.Description>
              </Step.Content>
            </Step>

            <Step
              disabled={succes_checkout ? false : true}
              active={this.state.step === 3}
              onClick={() => this.changeStep(3)}
            >
              <Icon name="info" />
              <Step.Content>
                <Step.Title>Confirmar pago</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <Step.Group disabled attached="top">
            <Step
              active={this.state.step === 1}
              onClick={() => this.changeStep(1)}
            >
              <Icon name="shopping basket" />
              <Step.Content>
                <Step.Title>Planes</Step.Title>
                <Step.Description>Elige un plan</Step.Description>
              </Step.Content>
            </Step>

            <Step
              disabled={plan ? false : true}
              active={this.state.step === 2}
              onClick={() => this.changeStep(2)}
            >
              <Icon name="payment" />
              <Step.Content>
                <Step.Title>Datos</Step.Title>
                <Step.Description>Ingresar datos de compra</Step.Description>
              </Step.Content>
            </Step>

            <Step
              disabled={succes_checkout ? false : true}
              active={this.state.step === 3}
              onClick={() => this.changeStep(3)}
            >
              <Icon name="info" />
              <Step.Content>
                <Step.Title>Confirmar pago</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Responsive>

        <Segment attached className="section-step-sale">
          {this.state.step === 1 && (
            <Grid stackable columns={3}>
              <Grid.Column>
                <Segment>
                  <div
                    style={{
                      textAlign: 'center',
                      paddingTop: '1rem',
                      paddingBottom: '1rem'
                    }}
                  >
                    <Header
                      style={{ fontSize: '1.5rem', fontWeight: 700 }}
                      as="h2"
                    >
                      BASICO
                    </Header>
                    <Divider />
                    <Header style={{ fontSize: '2.4rem' }} as="h2">
                      $999
                    </Header>
                    <p>6 meses de anuncio</p>
                    <p>Ofertas ilimitadas</p>
                    <Button
                      onClick={() =>
                        this.setState({ step: 2, plan: 'BASIC', price: 999 })
                      }
                      fluid
                      color="teal"
                    >
                      Comprar
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <div
                    style={{
                      textAlign: 'center',
                      paddingTop: '1rem',
                      paddingBottom: '1rem'
                    }}
                  >
                    <Header
                      style={{ fontSize: '1.5rem', fontWeight: 700 }}
                      as="h2"
                    >
                      ELITE
                    </Header>
                    <Divider />
                    <Header style={{ fontSize: '2.4rem' }} as="h2">
                      $1,399
                    </Header>
                    <p>9 meses de anuncio</p>
                    <p>Ofertas ilimitadas</p>
                    <Button
                      onClick={() =>
                        this.setState({ step: 2, plan: 'ELIT', price: 1399 })
                      }
                      fluid
                      color="teal"
                    >
                      Comprar
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <div
                    style={{
                      textAlign: 'center',
                      paddingTop: '1rem',
                      paddingBottom: '1rem'
                    }}
                  >
                    <Header
                      style={{ fontSize: '1.5rem', fontWeight: 700 }}
                      as="h2"
                    >
                      PREMIUN
                    </Header>
                    <Divider />
                    <Header style={{ fontSize: '2.4rem' }} as="h2">
                      $1,699
                    </Header>
                    <p>12 meses de anuncio</p>
                    <p>Ofertas ilimitadas</p>
                    <p>2 meses extra de anuncio</p>
                    <p className="list-choise-plan">
                      <ul>
                        <li>
                          Actualizaciones exclusivas:
                          <ul>
                            <li>- Pagina web personalizable</li>
                          </ul>
                        </li>
                      </ul>
                    </p>
                    <Button
                      onClick={() =>
                        this.setState({ step: 2, plan: 'PREMIUN', price: 1699 })
                      }
                      fluid
                      color="teal"
                    >
                      Comprar
                    </Button>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid>
          )}

          <Responsive {...Responsive.onlyMobile}>
            {this.state.step === 2 && (
              <Segment style={{ width: '100%', margin: 'auto' }}>
                <Form size="hug">
                  <Header
                    as="h2"
                    content="Forma de pago"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <Divider />
                  <Number
                    value={card_number}
                    onChange={this.update_number_card}
                    render={({ getInputProps, valid }) => (
                      <div className="field">
                        <input
                          {...getInputProps()}
                          className={valid ? '' : 'error'}
                        />

                        {valid
                          ? ''
                          : card_number
                          ? 'Número de tarjeta no valido'
                          : ''}
                      </div>
                    )}
                  />
                  <Form.Group style={{ marginTop: '1rem' }} widths="equal">
                    <Expiration
                      defaultMonth={expiration_month}
                      defaultYear={expiration_year}
                      onChange={this.update_expire_date}
                      render={({ getInputProps, valid, value, error }) => (
                        <div className="field">
                          <input
                            {...getInputProps()}
                            className={valid ? '' : 'error'}
                          />
                          {valid
                            ? ''
                            : error === Expiration.ERROR_MONTHYEAR
                            ? 'Por favor ingresar mes y año valido'
                            : error === Expiration.ERROR_MONTH
                            ? 'Por favor ingresar mes valido'
                            : error === Expiration.ERROR_YEAR
                            ? 'Por favor ingresar año valido'
                            : error === Expiration.ERROR_PAST_DATE
                            ? 'Por favor ingrese una fecha a futuro.'
                            : ''}
                        </div>
                      )}
                    />
                    <div className="field">
                      <Cvc
                        masked
                        defaultValue={cvv}
                        onChange={this.update_cvv}
                        render={({ getInputProps, valid }) => (
                          <input
                            {...getInputProps()}
                            className={valid ? '' : 'error'}
                          />
                        )}
                      />
                    </div>
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Codigo postal"
                      type="numeric"
                      value={postal_code}
                      onChange={this.update_postal_code}
                    />
                  </Form.Group>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Nombre que aparece en la tarjeta"
                    onChange={this.update_name_card}
                    value={card_name}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Calle"
                      type="text"
                      value={card_street}
                      onChange={this.update_street}
                    />
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Colonia"
                      type="text"
                      value={card_neighborhood}
                      onChange={this.update_neighborhood}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Ciudad"
                      type="text"
                      onChange={this.update_city}
                      value={card_city}
                    />
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Estado"
                      type="text"
                      onChange={this.update_state}
                      value={card_state}
                    />
                  </Form.Group>
                  <Button
                    disabled={load_card}
                    content="Siguiente"
                    icon="arrow right"
                    color="blue"
                    labelPosition="right"
                    onClick={this.validate_on_openpay}
                  />
                </Form>
              </Segment>
            )}
          </Responsive>
          <Responsive {...Responsive.onlyTablet}>
            {this.state.step === 2 && (
              <Segment style={{ width: '80%', margin: 'auto' }}>
                <Form size="small">
                  <Header
                    as="h2"
                    content="Forma de pago"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <Divider />
                  <Number
                    value={card_number}
                    onChange={this.update_number_card}
                    render={({ getInputProps, valid }) => (
                      <div className="field">
                        <input
                          {...getInputProps()}
                          className={valid ? '' : 'error'}
                        />

                        {valid
                          ? ''
                          : card_number
                          ? 'Número de tarjeta no valido'
                          : ''}
                      </div>
                    )}
                  />
                  <Form.Group style={{ marginTop: '1rem' }} widths="equal">
                    <Expiration
                      defaultMonth={expiration_month}
                      defaultYear={expiration_year}
                      onChange={this.update_expire_date}
                      render={({ getInputProps, valid, value, error }) => (
                        <div className="field">
                          <input
                            {...getInputProps()}
                            className={valid ? '' : 'error'}
                          />
                          {valid
                            ? ''
                            : error === Expiration.ERROR_MONTHYEAR
                            ? 'Por favor ingresar mes y año valido'
                            : error === Expiration.ERROR_MONTH
                            ? 'Por favor ingresar mes valido'
                            : error === Expiration.ERROR_YEAR
                            ? 'Por favor ingresar año valido'
                            : error === Expiration.ERROR_PAST_DATE
                            ? 'Por favor ingrese una fecha a futuro.'
                            : ''}
                        </div>
                      )}
                    />
                    <div className="field">
                      <Cvc
                        masked
                        defaultValue={cvv}
                        onChange={this.update_cvv}
                        render={({ getInputProps, valid }) => (
                          <input
                            {...getInputProps()}
                            className={valid ? '' : 'error'}
                          />
                        )}
                      />
                    </div>
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Codigo postal"
                      type="numeric"
                      value={postal_code}
                      onChange={this.update_postal_code}
                    />
                  </Form.Group>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Nombre que aparece en la tarjeta"
                    onChange={this.update_name_card}
                    value={card_name}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Calle"
                      type="text"
                      value={card_street}
                      onChange={this.update_street}
                    />
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Colonia"
                      type="text"
                      value={card_neighborhood}
                      onChange={this.update_neighborhood}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Ciudad"
                      type="text"
                      onChange={this.update_city}
                      value={card_city}
                    />
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Estado"
                      type="text"
                      onChange={this.update_state}
                      value={card_state}
                    />
                  </Form.Group>
                  <Button
                    disabled={load_card}
                    content="Siguiente"
                    icon="arrow right"
                    color="blue"
                    labelPosition="right"
                    onClick={this.validate_on_openpay}
                  />
                </Form>
              </Segment>
            )}
          </Responsive>
          <Responsive {...Responsive.onlyComputer}>
            {this.state.step === 2 && (
              <Segment style={{ width: '60%', margin: 'auto' }}>
                <Form size="small">
                  <Header
                    as="h2"
                    content="Forma de pago"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <Divider />
                  <Number
                    value={card_number}
                    onChange={this.update_number_card}
                    render={({ getInputProps, valid }) => (
                      <div className="field">
                        <input
                          {...getInputProps()}
                          className={valid ? '' : 'error'}
                        />

                        {valid
                          ? ''
                          : card_number
                          ? 'Número de tarjeta no valido'
                          : ''}
                      </div>
                    )}
                  />
                  <Form.Group style={{ marginTop: '1rem' }} widths="equal">
                    <Expiration
                      defaultMonth={expiration_month}
                      defaultYear={expiration_year}
                      onChange={this.update_expire_date}
                      render={({ getInputProps, valid, value, error }) => (
                        <div className="field">
                          <input
                            {...getInputProps()}
                            className={valid ? '' : 'error'}
                          />
                          {valid
                            ? ''
                            : error === Expiration.ERROR_MONTHYEAR
                            ? 'Por favor ingresar mes y año valido'
                            : error === Expiration.ERROR_MONTH
                            ? 'Por favor ingresar mes valido'
                            : error === Expiration.ERROR_YEAR
                            ? 'Por favor ingresar año valido'
                            : error === Expiration.ERROR_PAST_DATE
                            ? 'Por favor ingrese una fecha a futuro.'
                            : ''}
                        </div>
                      )}
                    />
                    <div className="field">
                      <Cvc
                        masked
                        defaultValue={cvv}
                        onChange={this.update_cvv}
                        render={({ getInputProps, valid }) => (
                          <input
                            {...getInputProps()}
                            className={valid ? '' : 'error'}
                          />
                        )}
                      />
                    </div>
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Codigo postal"
                      type="numeric"
                      value={postal_code}
                      onChange={this.update_postal_code}
                    />
                  </Form.Group>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Nombre que aparece en la tarjeta"
                    onChange={this.update_name_card}
                    value={card_name}
                  />
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Calle"
                      type="text"
                      value={card_street}
                      onChange={this.update_street}
                    />
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Colonia"
                      type="text"
                      value={card_neighborhood}
                      onChange={this.update_neighborhood}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Ciudad"
                      type="text"
                      onChange={this.update_city}
                      value={card_city}
                    />
                    <Form.Input
                      fluid
                      icon="shop"
                      iconPosition="left"
                      placeholder="Estado"
                      type="text"
                      onChange={this.update_state}
                      value={card_state}
                    />
                  </Form.Group>
                  <Button
                    disabled={load_card}
                    content="Siguiente"
                    icon="arrow right"
                    color="blue"
                    labelPosition="right"
                    onClick={this.validate_on_openpay}
                  />
                </Form>
              </Segment>
            )}
          </Responsive>
          {this.state.step === 3 && (
            <Grid stackable columns={2}>
              <Grid.Column computer="10">
                <Segment>
                  <Header
                    as="h2"
                    content="Información final"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <Divider />
                  Al completar la compra esta compra acepta estar de acuerdo con
                  el aviso de privacidad y terminos de uso del mismo.
                  <Header
                    as="h2"
                    content="Referido"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  Si tienes un codigo de alguien que te refiera agregalo en el
                  siguiente campo. Ese codigo te proporcionara 1 mes extra de
                  anuncio.
                  <p style={{ marginTop: '1rem' }}>
                    <Input
                      action={{
                        icon: 'check',
                        color: referrals_id ? 'teal' : 'gray'
                      }}
                      placeholder="Codigo de referencia"
                      onChange={this.validate_code}
                    />
                  </p>
                  <Divider />
                </Segment>
              </Grid.Column>
              <Grid.Column computer="6">
                <Segment>
                  <Header
                    as="h2"
                    content="Total a pagar"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <Divider />
                  <p>Plan {plan}</p>
                  <p>
                    {plan_list[plan].month} Meses de anuncio +{' '}
                    {plan_list[plan].present} Meses gratis{' '}
                    {referrals_id ? '+ 1 mes de referencia' : ''}
                  </p>
                  <p>
                    Total{' '}
                    {referrals_id
                      ? plan_list[plan].total_refered
                      : plan_list[plan].total}{' '}
                    meses
                  </p>
                  <Header
                    as="h2"
                    content="Total a pagar"
                    inverted
                    style={{
                      textAlign: 'left',
                      fontWeight: 'normal',
                      marginBottom: 0,
                      color: '#15bfc2',
                      fontFamily: 'Montserrat'
                    }}
                  />
                  <Divider />
                  <Header
                    as="h2"
                    content={`$${
                      `${price}`.length > 3
                        ? `${`${price}`[0]},${`${price}`.slice(1)}`
                        : price
                    }`}
                  />
                  <Button
                    onClick={this.pay_services}
                    content="Confirmar"
                    fluid
                    color="teal"
                  />
                </Segment>
              </Grid.Column>
            </Grid>
          )}
        </Segment>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size="small"
        >
          <Modal.Content>
            <h3>
              La compra{' '}
              {fail_pay ? 'ha sido declinada' : 'se ha realizado con exito'}
            </h3>
          </Modal.Content>
          <Modal.Actions>
            {success_pay && (
              <Button color="green" onClick={this.handleClose} inverted>
                <Icon name="checkmark" /> Continuar
              </Button>
            )}

            {fail_pay && (
              <Button color="red" onClick={this.handleClose} inverted>
                <Icon name="checkmark" /> Cerrar
              </Button>
            )}
          </Modal.Actions>
        </Modal>
        <script
          type="text/javascript"
          src="https://resources.openpay.mx/lib/openpay-data.v1.min.js"
        />
      </Container>
    );
  }
}

const mapStateToProps = ({
  profileReducer: { user_id, fullname, phone, email },
  loginReducer: { token }
}) => ({
  user_id,
  fullname,
  phone,
  email,
  token
});
const mapDispatchToProps = {
  pay_shop,
  auth_code
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pay));
