import React, { Component } from 'react';
import {
  Grid,
  Header,
  Divider,
  Segment,
  List,
  Responsive
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  get_reffers_success,
  get_reffers_fail,
  get_win_month,
  get_win_all
} from '../../redux/actions/Profile';

class References extends Component {
  state = { open: false };

  show = dimmer => () => this.setState({ dimmer, open: true });

  close = () => this.setState({ open: false });

  componentDidMount = () => {
    const {
      get_reffers_success,
      get_reffers_fail,
      get_win_month,
      get_win_all,
      token
    } = this.props;

    get_reffers_success(token);
    get_reffers_fail(token);
    get_win_month(token);
    get_win_all(token);
  };

  ComponentGlobal = columns => {
    const {
      my_code_reffer = '',
      referrals_success = [],
      reffers_fail = [],
      month_win = 0,
      win_all = 0
    } = this.props;

    return (
      <Grid stackable columns={columns}>
        <Grid.Column>
          <Segment>
            <Header
              as="h2"
              content="Codigo de referencia"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat'
              }}
            />
            <Divider />
            <Header
              as="h5"
              textAlign="center"
              content={my_code_reffer}
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat'
              }}
            />
            Refiere a un negocio que adquiera un paquete y por cada paquete
            puedes ganar hasta $200 pesos.
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Header
              as="h2"
              content="Referenciados confirmados"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat'
              }}
            />
            <Divider />
            <List divided relaxed>
              {referrals_success.map(
                ({ shop_name, day_used, money_win }, k) => {
                  return (
                    <List.Item key={k}>
                      <List.Icon
                        name="checkmark"
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header as="a">{shop_name}</List.Header>
                        <List.Description as="a">
                          Comprado {day_used.slice(0, 10)}
                        </List.Description>
                        <List.Description as="a">
                          ${money_win}.00
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                }
              )}
            </List>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Header
              as="h2"
              content="Referenciados fallidos"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat'
              }}
            />
            <Divider />
            <List divided relaxed>
              {reffers_fail.map(({ shop_name }, k) => {
                return (
                  <List.Item key={k}>
                    <List.Icon
                      name="cancel"
                      size="large"
                      verticalAlign="middle"
                    />
                    <List.Content>
                      <List.Header as="a">shop_name</List.Header>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Header
              as="h3"
              content="Total del mes"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat'
              }}
            />
            ${month_win}.00
            <Divider />
            <Header
              as="h3"
              content="Total historico"
              inverted
              style={{
                textAlign: 'left',
                fontWeight: 'normal',
                marginBottom: 0,
                color: '#15bfc2',
                fontFamily: 'Montserrat'
              }}
            />
            ${win_all}.00
            <Divider />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  };

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.ComponentGlobal(1)}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.ComponentGlobal(2)}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.ComponentGlobal(4)}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({
  profileReducer: {
    my_code_reffer,
    referrals_success,
    reffers_fail,
    month_win,
    win_all
  },
  loginReducer: { token }
}) => ({
  my_code_reffer,
  referrals_success,
  reffers_fail,
  month_win,
  win_all,
  token
});

const mapDispatchToProps = {
  get_reffers_success,
  get_reffers_fail,
  get_win_month,
  get_win_all
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(References)
);
