import React, { Component } from 'react';
import {
  List,
  Container,
  Header,
  Label,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ShopInformation extends Component {
  static propTypes = {};

  render() {
    const {
      shop_name,
      address,
      phone,
      phone2,
      accept_card = '0',
      score_shop = 0,
    } = this.props;
    console.log(this.props);
    return (
      <Segment
        style={{
          paddingBottom: '5em',
          fontWeight: '700',
          backgroundColor: 'white',
        }}
        stacked
      >
        <Container>
          <List>
            <List.Item>
              <Header
                as="h1"
                content={shop_name}
                inverted
                style={{
                  textAlign: 'left',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  color: '#15bfc2',
                  fontFamily: 'Montserrat',
                }}
              />
            </List.Item>
            <List.Item>
              <Label as="a" color="yellow" image size="mini">
                <Icon name="star" />
                <Label.Detail>
                  {score_shop > 0 ? score_shop : 'Aun no se ha calificado'}
                </Label.Detail>
              </Label>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>{address}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" />
              <List.Content>
                {phone} {phone && phone2 && '--'} {phone2}
              </List.Content>
            </List.Item>
            {accept_card == '1' && (
              <List.Item>
                <List.Icon name="credit card" />
                <List.Content>Acepta tarjeta</List.Content>
              </List.Item>
            )}
          </List>
        </Container>
      </Segment>
    );
  }
}

const mapStateToProps = ({
  shopReducer: { shop_name, address, phone, phone2, accept_card, score_shop },
}) => ({
  shop_name,
  address,
  phone,
  phone2,
  accept_card,
  score_shop,
});

export default withRouter(connect(mapStateToProps, null)(ShopInformation));
