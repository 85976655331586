import React, { Component } from 'react';
import { Segment, Grid, Message, Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
  VerifyAcount,
  ChangeStatusActiveError
} from '../../redux/actions/Register';

class VerificationCodeActivation extends Component {
  state = {
    name: '',
    code: '',
    error_fields: false
  };

  handleInputValues = (e, type) => {
    switch (type) {
      case 'email':
        this.setState({ email: e.target.value.trim() });
        break;
      case 'code':
        this.setState({ code: e.target.value.trim() });
        break;
      default:
        break;
    }
  };

  submitRegister = () => {
    const { email: EmailVerify, code } = this.state;
    const { email_register } = this.props;
    const email = EmailVerify ? EmailVerify : email_register;

    if (email && code) {
      this.props.VerifyAcount(email, code);

      setTimeout(() => {
        if (this.props.error_at_active) {
          this.props.ChangeStatusActiveError(false);
          return;
        }

        this.props.history.push('/login');
      }, 5000);
    } else {
      this.setState({
        error_fields: true
      });

      setTimeout(() => {
        this.setState({
          error_fields: false
        });
      }, 5000);
    }
  };

  render() {
    const { aconunt_active, error_at_active, email_register } = this.props;
    const { error_fields, email } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: '80vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Segment stacked>
            <Message
              info
              header="Código de verificación"
              content="El codigo de verificación fue enviada al correo con el que te registraste, busca en la carpeta de spam."
            />
            <Form size="large">
              <Form.Input
                required
                fluid
                icon="at"
                iconPosition="left"
                placeholder="Correo"
                value={email ? email : email_register}
                type="email"
                onChange={e => this.handleInputValues(e, 'email')}
              />
              <Form.Input
                required
                fluid
                icon="hashtag"
                iconPosition="left"
                placeholder="Código de verificación"
                onChange={e => this.handleInputValues(e, 'code')}
              />
              <Button
                onClick={this.submitRegister}
                color="teal"
                fluid
                size="large"
              >
                Verificar
              </Button>
            </Form>
            {error_at_active && (
              <Message
                error
                header="El codigo de verificación falló"
                content="El código de verificación no existe o ha caducado."
              />
            )}

            {error_fields && (
              <Message
                error
                header="El campo de verificacion no puede estar vacio"
                content="Asegurate de agregar el codigo de verificación."
              />
            )}

            {aconunt_active && (
              <Message
                positive
                header="La cuenta se ha activado"
                content="La cuenta ha sido activada con exito."
              />
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({
  registerReducer: { aconunt_active, error_at_active, email_register }
}) => ({
  aconunt_active,
  error_at_active,
  email_register
});

const mapDispatchToProps = {
  VerifyAcount,
  ChangeStatusActiveError
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerificationCodeActivation)
);
