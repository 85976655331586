import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

import BodyShops from './body';

class CreateShop extends Component {
  render() {
    return (
      <Container>
        <BodyShops key={Date.now()} title_service="Mi nuevo servicio" />
      </Container>
    );
  }
}

export default CreateShop;
