import { createReducer } from '../store';

const registerReducer = createReducer(
  {
    error_register: false,
    aconunt_active: false,
    error_at_active: false,
    email_register: ''
  },
  {
    REGISTER_SUCCESS(state, action) {
      return {
        ...state
      };
    },
    REGISTER_FAIL(state, action) {
      return {
        ...state,
        error_register: true
      };
    },
    REGISTER_ERROR(state, action) {
      const { error_register } = action.payload;
      return {
        ...state,
        error_register
      };
    },
    VERIFY_ACOUNT_SUCCESS(state, action) {
      return {
        ...state,
        aconunt_active: true
      };
    },
    VERIFY_ACOUNT_FAIL(state, action) {
      return {
        ...state,
        error_at_active: true
      };
    },
    CHANGE_ACTIVE_ERROR(state, action) {
      const { boolean } = action.payload;
      return {
        ...state,
        error_at_active: boolean,
        aconunt_active: boolean
      };
    },
    EMAIL_REGISTER(state, action) {
      const { email } = action.payload;
      return {
        ...state,
        email_register: email
      };
    }
  }
);

export default registerReducer;
