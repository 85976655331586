import { createReducer } from '../store';

const offersReducer = createReducer(
  {
    offers: [],
    nearby_offers: []
  },
  {
    SAVE_OFFER_SUCCESS(state, action) {
      return {
        ...state
      };
    },
    UPDATE_OFFER_SUCCESS(state, action) {
      return {
        ...state
      };
    },
    GET_MY_OFFERS_SUCCESS(state, action) {
      const {
        data: { offers: offers_server = [] }
      } = action.payload;
      let { offers = [] } = state;
      return {
        ...state,
        offers: offers.concat(offers_server)
      };
    },
    GET_MY_OFFERS_LAST_SUCCESS(state, action) {
      const {
        data: { offers: offers_server = [] }
      } = action.payload;
      let { offers = [] } = state;
      return {
        ...state,
        offers: [offers_server[0]].concat(offers)
      };
    },
    GET_MY_OFFERS_UPDATE_SUCCESS(state, action) {
      const {
        data: { offers: offers_server = [] }
      } = action.payload;
      return {
        ...state,
        offers: offers_server
      };
    },
    // NEARBY_OFFERS_SUCCESS(state, action) {
    //   const {
    //     data: { offers }
    //   } = action.payload;

    //   return {
    //     ...state,
    //     nearby_offers: offers
    //   };
    // },
    UPDATE_OFFERS_SET(state, action) {
      return {
        ...state,
        offers: []
      };
    }
  }
);

export default offersReducer;
