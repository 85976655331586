import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { get_shop, get_comments } from '../../redux/actions/Shop';

import Port from './port';
import ShopInformation from './shop-information';
import ContentShop from './content-shop';
import Gallery from './gallery';
import Comments from './comments';

class Shop extends Component {
  static propTypes = {};

  componentWillMount = () => {
    const {
      get_shop,
      match: {
        params: { shop_id }
      }
    } = this.props;

    get_shop(shop_id);
  };

  render() {
    return (
      <Container>
        <Port />
        <ShopInformation />
        <ContentShop />
        <Gallery />
        <Comments />
      </Container>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  get_shop,
  get_comments
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Shop)
);
