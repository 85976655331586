import { createReducer } from '../store';

const profileReducer = createReducer(
  {
    user_id: '',
    fullname: '',
    email: '',
    phone: '',
    age: '',
    image: '',
    create_at: '',
    success_update: false,
    fail_update: false,
    fail_update_password: false,
    my_shops: [],
    my_code_reffer: '',
    referrals_success: [],
    reffers_fail: [],
    month_win: 0,
    win_all: 0,
    accept_card_active: 0,
    active: 0,
    active_days: 0,
    cover_image: '',
    description: '',
    domain: '',
    images_days: 0,
    logo: '',
    offers_active: 0,
    shop_id: null,
    shop_name: '',
    style_sheets: 1,
    subdomain: '',
    template_type: 1,
    pages_id: null,
    type_charge: ''
  },
  {
    GET_PROFILE_SUCCESS(state, action) {
      const {
        data: {
          user_id,
          fullname,
          email,
          phone,
          age,
          gender,
          image,
          create_at,
          code
        }
      } = action.payload;
      return {
        ...state,
        user_id,
        fullname,
        email,
        phone,
        age,
        gender,
        image,
        create_at,
        my_code_reffer: code
      };
    },
    UPDATE_PROFILE_SUCCESS(state, action) {
      return {
        ...state,
        success_update: true
      };
    },
    UPDATE_PROFILE_FAIL(state, action) {
      return {
        ...state,
        fail_update: true
      };
    },
    UPDATE_STATUS(state, action) {
      return {
        ...state,
        success_update: false,
        fail_update: false,
        fail_update_password: false
      };
    },
    UPDATE_PASSWORD_SUCCESS(state, action) {
      return {
        ...state
      };
    },
    UPDATE_PASSWORD_FAIL(state, action) {
      return {
        ...state,
        fail_update_password: true
      };
    },
    UPLOAD_IMAGE_PROFILE_SUCCESS(state, action) {
      const {
        data: { result }
      } = action.payload;

      return {
        ...state,
        image: result
      };
    },
    CHANGE_IMAGE_PROFILE_SUCCESS(state, action) {
      return {
        ...state
      };
    },
    GET_MY_SHOPS_SUCCESS(state, action) {
      const {
        data: { result }
      } = action.payload;
      let { my_shops = [] } = state;
      return {
        ...state,
        my_shops: my_shops.concat(result)
      };
    },
    GET_UPDATE_MY_SHOPS_SUCCESS(state, action) {
      const {
        data: { result }
      } = action.payload;

      return {
        ...state,
        my_shops: result
      };
    },
    GET_REFFERS_SUCCESS(state, action) {
      const {
        data: { referrals }
      } = action.payload;
      return {
        ...state,
        referrals_success: referrals
      };
    },
    GET_REFFERS_FAIL_SUCCESS(state, action) {
      const {
        data: { reffers_fail }
      } = action.payload;
      return {
        ...state,
        reffers_fail
      };
    },
    GET_WIN_MONTH_SUCCESS(state, action) {
      const {
        data: {
          earned_referrals: { money_win = 0 }
        }
      } = action.payload;

      return {
        ...state,
        month_win: money_win
      };
    },
    GET_WIN_ALL_SUCCESS(state, action) {
      const {
        data: {
          earned_referrals: { money_win = 0 }
        }
      } = action.payload;

      return {
        ...state,
        win_all: money_win
      };
    },
    GET_PAGE_INFO_SUCCESS(state, action) {
      const {
        data: {
          page: {
            accept_card_active,
            active,
            active_days,
            cover_image,
            description,
            domain,
            images_days,
            logo,
            offers_active,
            shop_id,
            shop_name,
            style_sheets,
            subdomain,
            template_type,
            pages_id,
            type_charge
          }
        }
      } = action.payload;
      console.log(action.payload);
      return {
        ...state,
        accept_card_active,
        active,
        active_days,
        cover_image,
        description,
        domain,
        images_days,
        logo,
        offers_active,
        shop_id,
        shop_name,
        style_sheets,
        subdomain,
        template_type,
        pages_id,
        type_charge
      };
    },
    SET_MY_SHOPS_EMPTY(state) {
      return {
        ...state,
        my_shops: []
      };
    }
  }
);

export default profileReducer;
