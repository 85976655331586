import React, { Component } from 'react';
import {
  Segment,
  Card,
  Image,
  Rating,
  Header,
  Divider,
  Modal,
  Button,
  Grid,
  List,
  Label,
  Icon,
  Responsive,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  get_my_shops,
  set_my_shops,
  get_update_my_shops,
} from '../../redux/actions/Profile';
import {
  get_my_offers,
  update_offer,
  set_offers,
  get_my_offers_update,
} from '../../redux/actions/Offers';
import { disable_shop, unlock_shop } from '../../redux/actions/Shop';

import OfferModal from './modal-offers';

class MyShopsList extends Component {
  state = {
    open: false,
    show_modal_offer: false,
    information_shop: {},
    offer_selected: {},
    page: 0,
    limit: 4,
    limit_offer: 1,
    page_offer: 0,
  };

  open_offer = () =>
    this.setState({ show_modal_offer: true, limit_offer: 7, page_offer: 0 });
  close_offer = () =>
    this.setState({ show_modal_offer: false, limit_offer: 7, page_offer: 0 });

  show = (dimmer, information_shop) => {
    const { set_offers } = this.props;
    this.setState(
      { dimmer, open: true, information_shop, limit_offer: 7, page_offer: 0 },
      () => {
        set_offers();
        this.get_offers_func();
      }
    );
  };

  close = () => this.setState({ open: false });

  get_my_shops_fun = () => {
    const { get_my_shops, token } = this.props;
    const { page, limit } = this.state;

    get_my_shops(token, limit, page);
  };

  componentDidMount = () => {
    const { set_my_shops } = this.props;
    set_my_shops();
    this.get_my_shops_fun();
  };

  get_offers_func = () => {
    const { token, get_my_offers } = this.props;
    const { page_offer, limit_offer } = this.state;
    const {
      information_shop: { shop_id },
    } = this.state;

    get_my_offers(token, shop_id, page_offer, limit_offer);
  };

  active_offer = (offers_id) => {
    const { update_offer, token, get_my_offers_update, offers } = this.props;
    const {
      information_shop: { shop_id },
    } = this.state;
    update_offer(token, offers_id, {
      active: 1,
      shop_id,
    }).then((result) => {
      if (result.type === 'UPDATE_OFFER_SUCCESS') {
        get_my_offers_update(token, shop_id, 0, offers.length);
      }
    });
  };

  set_current_offer = (data) => {
    this.setState(
      {
        offer_selected: data,
      },
      () => {
        this.open_offer();
      }
    );
  };

  desactive_offer = (offers_id) => {
    const { update_offer, token, get_my_offers_update, offers } = this.props;
    const {
      information_shop: { shop_id },
    } = this.state;
    update_offer(token, offers_id, {
      active: 0,
      shop_id,
    }).then((result) => {
      if (result.type === 'UPDATE_OFFER_SUCCESS') {
        get_my_offers_update(token, shop_id, 0, offers.length);
      }
    });
  };

  desactive_shop = () => {
    const { disable_shop, token, get_update_my_shops, my_shops } = this.props;
    const {
      information_shop: { shop_id },
    } = this.state;

    disable_shop(shop_id, token).then((result) => {
      if (result.type === 'DISABLE_SHOP_SUCCESS') {
        get_update_my_shops(token, my_shops.length, 0);
        this.close();
      }
    });
  };

  active_shop = () => {
    const { unlock_shop, token, get_update_my_shops, my_shops } = this.props;
    const {
      information_shop: { shop_id },
    } = this.state;

    unlock_shop(shop_id, token).then((result) => {
      if (result.type === 'UNLOCK_SHOP_SUCCESS') {
        get_update_my_shops(token, my_shops.length, 0);
        this.close();
      }
    });
  };

  gruop_cards = (type) => {
    const {
      my_shops = [],
      services_types = [],
      sub_service_types = [],
    } = this.props;
    console.log(my_shops, 'de nuevo');
    return (
      <Card.Group centered>
        {my_shops.map((data) => {
          const {
            shop_id,
            cover_image,
            shop_name,
            address,
            service_name,
            sub_service_name,
            score_shop,
          } = data;
          const split_img = cover_image.split('/upload/');
          let image_standar = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_300,h_300/${split_img[1]}`;
          return (
            <Card
              style={{
                width:
                  type == 'computer'
                    ? '250px'
                    : type == 'tablet'
                    ? '45%'
                    : '100%',
              }}
              key={shop_id}
              onClick={() => this.show('blurring', data)}
            >
              <Image
                src={image_standar}
                style={{
                  width:
                    type == 'computer'
                      ? '250px'
                      : type == 'tablet'
                      ? '100%%'
                      : '100%',
                  height: '170px !important',
                }}
                wrapped
                ui={false}
              />
              <Card.Content>
                <Card.Header
                  style={type == 'mobile' ? { fontSize: '2rem' } : {}}
                  className="text-color-kimvex"
                >
                  {shop_name}
                </Card.Header>
                <Card.Description
                  style={type == 'mobile' ? { fontSize: '1.4rem' } : {}}
                >
                  {address}
                </Card.Description>
                <Card.Meta
                  style={type == 'mobile' ? { fontSize: '1.3rem' } : {}}
                >
                  {services_types[service_name]}{' '}
                  {sub_service_types[sub_service_name]}
                </Card.Meta>
              </Card.Content>
              <Card.Content
                style={type == 'mobile' ? { fontSize: '1.3rem' } : {}}
                extra
              >
                <Rating
                  className="left floated"
                  disabled
                  icon="star"
                  defaultRating={score_shop}
                  maxRating={4}
                />
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>
    );
  };

  render() {
    const {
      open,
      dimmer,
      information_shop: {
        shop_name: name_of_shop,
        status,
        date_init = '',
        date_finish = '',
        score_shop,
        shop_id,
        type_charge,
        lock_shop,
      },
      offer_selected,
      show_modal_offer,
      page_offer,
      limit_offer,
    } = this.state;

    const {
      my_shops = [],
      offers = [],
      services_types = [],
      sub_service_types = [],
    } = this.props;
    console.log(my_shops);
    return (
      <Segment
        stackable
        style={{ height: '600px', overflowY: 'auto', paddingBottom: '1rem' }}
      >
        <Header
          as="h2"
          content="Mis tiendas"
          inverted
          style={{
            textAlign: 'left',
            fontWeight: 'normal',
            marginBottom: 0,
            color: '#15bfc2',
            fontFamily: 'Montserrat',
          }}
        />
        <Divider />
        <Responsive {...Responsive.onlyMobile}>
          {this.gruop_cards('mobile')}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.gruop_cards('tablet')}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.gruop_cards('computer')}
        </Responsive>
        <div
          style={{ width: '100%', textAlign: 'center', padding: '1rem 0rem' }}
        >
          <Button
            size="large"
            basic
            color="teal"
            icon="plus"
            labelPosition="right"
            content="Mostrar más"
            onClick={() => {
              this.setState(
                {
                  page: this.state.page + 1,
                  limit: 4,
                },
                () => {
                  this.get_my_shops_fun();
                }
              );
            }}
          />
        </div>
        <Modal dimmer={dimmer} open={open} onClose={this.close}>
          <Modal.Header>{name_of_shop}</Modal.Header>
          <Modal.Content>
            <Grid stackable columns={2}>
              <Grid.Column computer="6">
                <Modal.Description
                  style={{ maxHeigth: '250px', overflowY: 'auto' }}
                >
                  <List>
                    {status == '1' ? (
                      <List.Item>
                        <Label as="a" color="green" image size="medium">
                          <Icon name="checkmark" />
                          <Label.Detail>Actualmente activo</Label.Detail>
                        </Label>
                      </List.Item>
                    ) : (
                      <List.Item>
                        <Label as="a" color="red" image size="medium">
                          <Icon name="cancel" />
                          <Label.Detail>Actualmente inactivo</Label.Detail>
                        </Label>
                      </List.Item>
                    )}
                    {status === '1' && (
                      <List.Item>
                        <Label as="a" color="teal" image size="medium">
                          <Icon name="checked calendar" />
                          <Label.Detail>
                            Fecha de inicio: {date_init.slice(0, 10)}
                          </Label.Detail>
                        </Label>
                      </List.Item>
                    )}

                    {status === '1' && (
                      <List.Item>
                        <Label as="a" color="orange" image size="medium">
                          <Icon name="delete calendar" />
                          <Label.Detail>
                            Fecha de finalización: {date_finish.slice(0, 10)}
                          </Label.Detail>
                        </Label>
                      </List.Item>
                    )}

                    <List.Item>
                      <Label as="a" color="yellow" image size="medium">
                        <Icon name="star" />
                        Calificación
                        <Label.Detail>{score_shop}</Label.Detail>
                      </Label>
                    </List.Item>
                  </List>
                </Modal.Description>
              </Grid.Column>
              <Grid.Column computer="10">
                <Modal.Description
                  style={{ maxHeight: '250px', overflowY: 'auto' }}
                >
                  <Header>Mis ofertas</Header>
                  <List divided>
                    {offers.map((data_info, k) => {
                      const {
                        offers_id,
                        title,
                        description,
                        date_end,
                        image_url,
                        active,
                      } = data_info;

                      const split_img = image_url.split('/upload/');
                      let image_standar = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_300,h_300/${split_img[1]}`;

                      console.log(data_info);
                      return (
                        <List.Item key={k}>
                          <Image avatar src={image_standar} />
                          <List.Content>
                            <List.Header as="a">{title}</List.Header>
                            <List.Description>{description}</List.Description>
                            <List.Description>
                              Fecha de expiracion: {date_end.slice(0, 10)}
                            </List.Description>
                          </List.Content>
                          <List.Content>
                            <Button
                              size="mini"
                              basic
                              color="teal"
                              icon="edit"
                              labelPosition="right"
                              content="Editar"
                              onClick={() => this.set_current_offer(data_info)}
                            />
                            {active === '0' && (
                              <Button
                                size="mini"
                                basic
                                positive
                                icon="checkmark"
                                labelPosition="right"
                                content="Activar"
                                onClick={() => this.active_offer(offers_id)}
                              />
                            )}
                            {active === '1' && (
                              <Button
                                size="mini"
                                basic
                                negative
                                icon="cancel"
                                labelPosition="right"
                                content="Desactivar"
                                onClick={() => this.desactive_offer(offers_id)}
                              />
                            )}
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      padding: '1rem 0rem',
                    }}
                  >
                    <Button
                      size="large"
                      basic
                      color="teal"
                      icon="plus"
                      labelPosition="right"
                      content="Mostrar más"
                      onClick={() => {
                        this.setState(
                          {
                            page_offer: this.state.page_offer + 1,
                            limit_offer: 7,
                          },
                          () => {
                            this.get_offers_func();
                          }
                        );
                      }}
                    />
                  </div>
                </Modal.Description>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions style={{ textAlign: 'center' }}>
            <Responsive
              style={{ paddingBottom: '1rem' }}
              {...Responsive.onlyMobile}
            >
              <Button.Group
                style={{ padding: '1rem !important' }}
                compact
                size="mini"
                widths="7"
              >
                <Button size="mini" color="gray" onClick={this.close}>
                  <Icon name="close"></Icon>
                </Button>
                <Button
                  as={Link}
                  to={`/edit-shop/${shop_id}`}
                  size="mini"
                  color="teal"
                  content="Editar"
                  onClick={this.close}
                />

                {status === '0' && lock_shop === '0' ? (
                  <Button
                    as={Link}
                    to={`/shop/${shop_id}/billing`}
                    size="mini"
                    positive
                    content="Activar"
                    onClick={this.close}
                  />
                ) : status === '1' && lock_shop === '0' ? (
                  <Button
                    size="mini"
                    negative
                    onClick={() => this.desactive_shop()}
                  >
                    <Icon name="power off"></Icon>
                  </Button>
                ) : (
                  <Button
                    size="mini"
                    color="green"
                    onClick={() => this.active_shop()}
                  >
                    <Icon name="power off"></Icon>
                  </Button>
                )}
                {status !== '0' && (
                  <Button
                    size="mini"
                    color="blue"
                    content="Oferta"
                    onClick={() => {
                      this.setState(
                        {
                          offer_selected: {},
                        },
                        () => {
                          this.open_offer();
                        }
                      );
                    }}
                  />
                )}
                {status !== '0' && type_charge === 'PREMIUN' && (
                  <Button
                    as={Link}
                    to={`/${shop_id}/page`}
                    size="mini"
                    color="blue"
                    content="Pagina"
                  />
                )}
              </Button.Group>
            </Responsive>
            <Responsive {...Responsive.onlyTablet}>
              <Button.Group>
                <Button size="mini" color="gray" onClick={this.close}>
                  Cerrar
                </Button>
                <Button
                  as={Link}
                  to={`/edit-shop/${shop_id}`}
                  size="mini"
                  color="teal"
                  icon="edit"
                  labelPosition="right"
                  content="Editar"
                  onClick={this.close}
                />

                {status === '0' && lock_shop === '0' ? (
                  <Button
                    as={Link}
                    to={`/shop/${shop_id}/billing`}
                    size="mini"
                    positive
                    content="Activar"
                    onClick={this.close}
                  />
                ) : status === '1' && lock_shop === '0' ? (
                  <Button
                    size="mini"
                    negative
                    onClick={() => this.desactive_shop()}
                  >
                    <Icon name="power off"></Icon>
                  </Button>
                ) : (
                  <Button
                    size="mini"
                    color="green"
                    onClick={() => this.active_shop()}
                  >
                    <Icon name="power off"></Icon>
                  </Button>
                )}
                {status !== '0' && (
                  <Button
                    size="mini"
                    color="blue"
                    icon="add"
                    labelPosition="right"
                    content="Oferta"
                    onClick={() => {
                      this.setState(
                        {
                          offer_selected: {},
                        },
                        () => {
                          this.open_offer();
                        }
                      );
                    }}
                  />
                )}
                {status !== '0' && type_charge === 'PREMIUN' && (
                  <Button
                    as={Link}
                    to={`/${shop_id}/page`}
                    size="mini"
                    color="blue"
                    icon="add"
                    labelPosition="right"
                    content="Pagina"
                  />
                )}
              </Button.Group>
            </Responsive>
            <Responsive {...Responsive.onlyComputer}>
              <Button.Group>
                <Button size="mini" color="gray" onClick={this.close}>
                  Cerrar
                </Button>
                <Button
                  as={Link}
                  to={`/edit-shop/${shop_id}`}
                  size="mini"
                  color="teal"
                  icon="edit"
                  labelPosition="right"
                  content="Editar"
                  onClick={this.close}
                />

                {status === '0' && lock_shop === '0' ? (
                  <Button
                    as={Link}
                    to={`/shop/${shop_id}/billing`}
                    size="mini"
                    positive
                    content="Activar"
                    onClick={this.close}
                  />
                ) : status === '1' && lock_shop === '0' ? (
                  <Button
                    size="mini"
                    negative
                    onClick={() => this.desactive_shop()}
                  >
                    <Icon name="power off"></Icon>
                  </Button>
                ) : (
                  <Button
                    size="mini"
                    color="green"
                    onClick={() => this.active_shop()}
                  >
                    <Icon name="power off"></Icon>
                  </Button>
                )}
                {status !== '0' && (
                  <Button
                    size="mini"
                    color="blue"
                    icon="add"
                    labelPosition="right"
                    content="Oferta"
                    onClick={() => {
                      this.setState(
                        {
                          offer_selected: {},
                        },
                        () => {
                          this.open_offer();
                        }
                      );
                    }}
                  />
                )}
                {status !== '0' && type_charge === 'PREMIUN' && (
                  <Button
                    as={Link}
                    to={`/${shop_id}/page`}
                    size="mini"
                    color="blue"
                    icon="add"
                    labelPosition="right"
                    content="Pagina"
                  />
                )}
              </Button.Group>
            </Responsive>
          </Modal.Actions>
          {show_modal_offer && (
            <OfferModal
              show_modal_offer={this.state.show_modal_offer}
              close_offer={this.close_offer}
              shop_id={shop_id}
              {...offer_selected}
              page_offer={page_offer}
              limit_offer={limit_offer}
              offers={offers.length}
            />
          )}
        </Modal>
      </Segment>
    );
  }
}

const mapStateToProps = ({
  loginReducer: { token },
  profileReducer: { my_shops },
  offersReducer: { offers },
  generalReducer: { services_types, sub_service_types },
}) => ({
  my_shops,
  token,
  offers,
  services_types,
  sub_service_types,
});

const mapDispatchToProps = {
  get_my_shops,
  get_my_offers,
  update_offer,
  disable_shop,
  set_offers,
  set_my_shops,
  get_my_offers_update,
  unlock_shop,
  get_update_my_shops,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyShopsList)
);
