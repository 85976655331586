import { createReducer } from '../store';

const generalReducer = createReducer(
  {
    my_lat: '',
    my_lon: '',
    services_types: {
      CLINIC: 'Clinicas',
      SHOP: 'Tienda',
      RESTAURANT: 'Restaurante'
    },
    sub_service_types: {
      DERMATOLOGIST: 'Dermatologica',
      DENTIST: 'Dentista',
      OFTALMICA: 'Oftalmica',
      GROCERIES: 'Abarrotes',
      INN: 'Fonda'
    }
  },
  {
    UPDATE_POSITION(state, action) {
      const { lat, lon } = action.payload;
      return {
        ...state,
        my_lat: lat,
        my_lon: lon
      };
    }
  }
);

export default generalReducer;
