import React, { Component } from 'react';
import { Segment, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Port extends Component {
  static propTypes = {};

  render() {
    let { cover_image, logo } = this.props;
    const split_cover = cover_image.split('/upload/');
    cover_image = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_cover[1]}`;
    const split_logo = logo.split('/upload/');
    logo = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_logo[1]}`;

    return (
      <Segment
        className="image-cover"
        style={{ backgroundImage: `url(${cover_image})` }}
      >
        <div className="small-div">
          <Image
            size="small"
            src={logo}
            className="border-image-profile-shop"
            circular
            bordered
            style={{ width: 145, height: 145 }}
          />
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = ({ shopReducer: { cover_image, logo } }) => ({
  cover_image,
  logo
});

export default withRouter(connect(mapStateToProps, null)(Port));
