import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import axios from 'axios';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { multiClientMiddleware } from 'redux-axios-middleware';
import createFilter from 'redux-persist-transform-filter';

import reducers from './reducers';

const client = {
  default: {
    client: axios.create({
      // baseURL: 'http://localhost:3003/api',
      baseURL: 'https://api.kimvex.com/api'
    }),
  },
  pay_verify: {
    client: axios.create({
      baseURL: 'https://process.kimvex.com/api',
    }),
  },
};

const persistConfig = {
  key: 'root',
  storage,
  blackList: [],
  whiteList: [
    'loginReducer',
    'profileReducer',
    'generalReducer',
    'offersReducer',
  ],
  transforms: [
    createFilter('loginReducer', ['token']),
    createFilter('generalReducer', [
      'my_lat',
      'my_lon',
      'services_types',
      'sub_service_types',
    ]),
    createFilter('offersReducer', []),
    createFilter('profileReducer', [
      'fullname',
      'email',
      'phone',
      'age',
      'gender',
      'image',
      'create_at',
      'user_id',
      'my_code_reffer',
    ]),
  ],
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'SUBMIT_LOGOUT_REQUEST_SUCCESS') {
    localStorage.clear();
    sessionStorage.clear();

    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

export const makeStore = () => {
  let store = createStore(
    persistedReducer,
    applyMiddleware(thunkMiddleware, multiClientMiddleware(client))
  );

  let persistor = persistStore(store);

  return { store, persistor };
};

//---------------------------//

// const persistsReducer = persistReducer(persistConfig, reducer);

// const store = createStore(
//   persistsReducer,
//   applyMiddleware(axiosMiddleware(client))
// );
// const persistor = persistStore(store);

// export { store, persistor };
