import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
  Card,
  Header,
  Table,
  Icon,
  Responsive,
  Grid,
  Segment,
  Divider
} from 'semantic-ui-react';
import arrow from '../../iconfinder_arrow-right_227601.png';

class IntialPage extends Component {
  mini_movile = () => (
    <div className="container-complete">
      <div className="container-initial-page-movile-mini">
        <div className="information-of-service-movile-mini">
          <p className="information-text">
            Encuentra los servicios y ofertas que esten cerca de ti.
          </p>
          <div className="container-go-services-movile-mini">
            <NavLink className="go-services-mini" to="/services">
              Encontrar servicios{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
            <NavLink className="go-services-mini" to="/offers">
              Encontrar ofertas{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
          </div>
        </div>
        <div className="container-title-inital-page-movile-mini">
          <h1 className="title-initial-page-movile-mini">Kimvex</h1>
        </div>
      </div>

      <div className="second-information-section-movile-mini">
        <h2 className="sub-title-information">Anuncia tu negocio</h2>
        <div className="information-step-solds-movile-mini">
          <Card className="card-size">
            <Card.Content>
              <Card.Header className="steps-cards">Paso 1</Card.Header>
              <Card.Meta>
                <span className="date-movile-mini">Registrate</span>
              </Card.Meta>
              <Card.Description className="description-card-movile-mini">
                Ten una cuenta en la cual poder dar de alta a los negocios y
                ofertas que quieras.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card className="card-size">
            <Card.Content>
              <Card.Header className="steps-cards">Paso 2</Card.Header>
              <Card.Meta>
                <span className="date-movile-mini">
                  Da de alta a tu negocio
                </span>
              </Card.Meta>
              <Card.Description className="description-card-movile-mini">
                Agrega la información de tu negocio para que tus futuros
                clientes sepan de ti.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card className="card-size">
            <Card.Content>
              <Card.Header className="steps-cards">Paso 3</Card.Header>
              <Card.Meta>
                <span className="date-movile-mini">Activa tu negocio</span>
              </Card.Meta>
              <Card.Description className="description-card-movile-mini">
                Elige uno de los tres planes de anuncio para tu negocio.
              </Card.Description>
            </Card.Content>
          </Card>
        </div>
      </div>

      <div className="third-section-plans">
        <h2
          className="sub-title-information"
          style={{ width: '100px', borderColor: 'white', color: 'white' }}
        >
          Planes
        </h2>
        <Grid stackable columns={3}>
          <Grid.Column>
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <Header style={{ fontSize: '1.5rem', fontWeight: 700 }} as="h2">
                  BASICO
                </Header>
                <Divider />
                <Header style={{ fontSize: '2.4rem' }} as="h2">
                  $999
                </Header>
                <p>6 meses de anuncio</p>
                <p>Ofertas ilimitadas</p>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <Header style={{ fontSize: '1.5rem', fontWeight: 700 }} as="h2">
                  ELITE
                </Header>
                <Divider />
                <Header style={{ fontSize: '2.4rem' }} as="h2">
                  $1,399
                </Header>
                <p>9 meses de anuncio</p>
                <p>Ofertas ilimitadas</p>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <Header style={{ fontSize: '1.5rem', fontWeight: 700 }} as="h2">
                  PREMIUM
                </Header>
                <Divider />
                <Header style={{ fontSize: '2.4rem' }} as="h2">
                  $1,699
                </Header>
                <p>12 meses de anuncio</p>
                <p>Ofertas ilimitadas</p>
                <p>2 meses extra de anuncio</p>
                <p className="list-choise-plan">
                  <ul>
                    <li>
                      Actualizaciones exclusivas:
                      <ul>
                        <li>- Pagina web personalizable</li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

      <div className="quarter-section-movile-mini">
        <div className="refer-win-money-container-movile-mini">
          <img
            className="img-money-movile-mini"
            src="https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/v1581836107/container-assets/sandra-gabriel-cwk6ei6Jo0M-unsplash.jpg"
          />
          <div className="text-money-movile-mini">
            <h2 className="title-money-movile-mini">
              Gana dinero recomendando
            </h2>
            <p className="information-money-movile-mini">
              Registrate y obten tu codigo de referencia con el cual recomienda
              a negocios que se den de alta usandolo y podrias ganar hasta{' '}
              <h3>$200</h3>
            </p>
          </div>
        </div>
      </div>
      <footer className="footer-page-movile-mini">
        <div className="footer-information-kimvex-movile-mini">
          <span className="kimvxe-logo">Kimvex</span>
          <a href="https://kimvex.com" className="kmv-link">
            kimvex.com
          </a>
          <a
            href="https://desarrollo.kimvex.com/"
            className="kmv-link-development"
          >
            Desarollo de paginas web
          </a>
        </div>

        <div className="contact-information-kimvex-movile-mini">
          <span className="contact-us">
            Si quieres contactarnos de forma directa puedes llamarnos al
            siguiente número
          </span>
          <span className="contact-us">9931076334</span>
        </div>

        <div className="social-networks-kimvex-movile-mini">
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item"
          >
            <Icon name="facebook official" /> Facebook
          </a>
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item twitter"
          >
            <Icon name="twitter" /> Twitter
          </a>
        </div>

        <div className="copyright-movile-mini">
          <span className="information-copyright">
            Copyright © 2020 Kimvex. Todos los derechos reservados. Diseñado &
            Desarrollado por Kimvex
          </span>
        </div>
      </footer>
    </div>
  );

  movile_version = () => (
    <div className="container-complete">
      <div className="container-initial-page-movile">
        <div className="information-of-service">
          <p className="information-text">
            Encuentra los servicios y ofertas que esten cerca de ti.
          </p>
          <div className="container-go-services">
            <NavLink className="go-services" to="/services">
              Encontrar servicios{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
            <NavLink className="go-services" to="/offers">
              Encontrar ofertas{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
          </div>
        </div>
        <div className="container-title-inital-page-movile">
          <h1 className="title-initial-page-movile">Kimvex</h1>
        </div>
      </div>

      <div className="second-information-section-movile">
        <h2 className="sub-title-information">Anuncia tu negocio</h2>
        <div className="information-step-solds-movile">
          <Card className="card-size">
            <Card.Content>
              <Card.Header className="steps-cards">Paso 1</Card.Header>
              <Card.Meta>
                <span className="date-movile">Registrate</span>
              </Card.Meta>
              <Card.Description className="description-card-movile">
                Ten una cuenta en la cual poder dar de alta a los negocios y
                ofertas que quieras.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card className="card-size">
            <Card.Content>
              <Card.Header className="steps-cards">Paso 2</Card.Header>
              <Card.Meta>
                <span className="date-movile">Da de alta a tu negocio</span>
              </Card.Meta>
              <Card.Description className="description-card-movile">
                Agrega la información de tu negocio para que tus futuros
                clientes sepan de ti.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card className="card-size">
            <Card.Content>
              <Card.Header className="steps-cards">Paso 3</Card.Header>
              <Card.Meta>
                <span className="date-movile">Activa tu negocio</span>
              </Card.Meta>
              <Card.Description className="description-card-movile">
                Elige uno de los tres planes de anuncio para tu negocio.
              </Card.Description>
            </Card.Content>
          </Card>
        </div>
      </div>

      <div className="third-section-plans">
        <h2
          className="sub-title-information"
          style={{ width: '100px', borderColor: 'white', color: 'white' }}
        >
          Planes
        </h2>
        <Grid stackable columns={3}>
          <Grid.Column>
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <Header style={{ fontSize: '1.5rem', fontWeight: 700 }} as="h2">
                  BASICO
                </Header>
                <Divider />
                <Header style={{ fontSize: '2.4rem' }} as="h2">
                  $999
                </Header>
                <p>6 meses de anuncio</p>
                <p>Ofertas ilimitadas</p>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <Header style={{ fontSize: '1.5rem', fontWeight: 700 }} as="h2">
                  ELITE
                </Header>
                <Divider />
                <Header style={{ fontSize: '2.4rem' }} as="h2">
                  $1,399
                </Header>
                <p>9 meses de anuncio</p>
                <p>Ofertas ilimitadas</p>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <Header style={{ fontSize: '1.5rem', fontWeight: 700 }} as="h2">
                  PREMIUM
                </Header>
                <Divider />
                <Header style={{ fontSize: '2.4rem' }} as="h2">
                  $1,699
                </Header>
                <p>12 meses de anuncio</p>
                <p>Ofertas ilimitadas</p>
                <p>2 meses extra de anuncio</p>
                <p className="list-choise-plan">
                  <ul>
                    <li>
                      Actualizaciones exclusivas:
                      <ul>
                        <li>- Pagina web personalizable</li>
                      </ul>
                    </li>
                  </ul>
                </p>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>

      <div className="quarter-section-movile">
        <div className="refer-win-money-container-movile">
          <img
            className="img-money-movile"
            src="https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/v1581836107/container-assets/sandra-gabriel-cwk6ei6Jo0M-unsplash.jpg"
          />
          <div className="text-money-movile">
            <h2 className="title-money-movile">Gana dinero recomendando</h2>
            <p className="information-money-movile">
              Registrate y obten tu codigo de referencia con el cual recomienda
              a negocios que se den de alta usandolo y podrias ganar hasta{' '}
              <h3>$200</h3>
            </p>
          </div>
        </div>
      </div>
      <footer className="footer-page-movile">
        <div className="footer-information-kimvex-movile">
          <span className="kimvxe-logo">Kimvex</span>
          <a href="https://kimvex.com" className="kmv-link">
            kimvex.com
          </a>
          <a
            href="https://desarrollo.kimvex.com/"
            className="kmv-link-development"
          >
            Desarollo de paginas web
          </a>
        </div>

        <div className="contact-information-kimvex-movile">
          <span className="contact-us">
            Si quieres contactarnos de forma directa puedes llamarnos al
            siguiente número
          </span>
          <span className="contact-us">9931076334</span>
        </div>

        <div className="social-networks-kimvex-movile">
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item"
          >
            <Icon name="facebook official" /> Facebook
          </a>
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item twitter"
          >
            <Icon name="twitter" /> Twitter
          </a>
        </div>

        <div className="copyright-movile">
          <span className="information-copyright">
            Copyright © 2020 Kimvex. Todos los derechos reservados. Diseñado &
            Desarrollado por Kimvex
          </span>
        </div>
      </footer>
    </div>
  );

  tablet_version = () => (
    <div className="container-complete">
      <div className="container-initial-page-tablet">
        <div className="information-of-service">
          <p className="information-text">
            Encuentra los servicios y ofertas que esten cerca de ti.
          </p>
          <div className="container-go-services">
            <NavLink className="go-services" to="/services">
              Encontrar servicios{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
            <NavLink className="go-services" to="/offers">
              Encontrar ofertas{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
          </div>
        </div>
        <div className="container-title-inital-page-tablet">
          <h1 className="title-initial-page-tablet">Kimvex</h1>
        </div>
      </div>

      <div className="second-information-section-tablet">
        <h2 className="sub-title-information">Anuncia tu negocio</h2>
        <div className="information-step-solds">
          <Card className="card-size">
            <Card.Content>
              <Card.Header>Paso 1</Card.Header>
              <Card.Meta>
                <span className="date">Registrate</span>
              </Card.Meta>
              <Card.Description>
                Ten una cuenta en la cual poder dar de alta a los negocios y
                ofertas que quieras.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card className="card-size">
            <Card.Content>
              <Card.Header>Paso 2</Card.Header>
              <Card.Meta>
                <span className="date">Da de alta a tu negocio</span>
              </Card.Meta>
              <Card.Description>
                Agrega la información de tu negocio para que tus futuros
                clientes sepan de ti.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card className="card-size">
            <Card.Content>
              <Card.Header>Paso 3</Card.Header>
              <Card.Meta>
                <span className="date">Activa tu negocio</span>
              </Card.Meta>
              <Card.Description>
                Elige uno de los tres planes de anuncio para tu negocio.
              </Card.Description>
            </Card.Content>
          </Card>
        </div>
      </div>

      <div className="third-section-plans">
        <h2
          className="sub-title-information"
          style={{ width: '100px', borderColor: 'white', color: 'white' }}
        >
          Planes
        </h2>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table-plans" singleLine>
                Plan
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Meses
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Meses de regalo
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Ofertas ilimitadas
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Pagina web
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Precio
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as="h2" textAlign="center">
                  BASICO
                </Header>
              </Table.Cell>
              <Table.Cell singleLine>
                <Header as="h4" textAlign="center">
                  6
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  1
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="red" name="x" />
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  $999
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h2" textAlign="center">
                  ELITE
                </Header>
              </Table.Cell>
              <Table.Cell singleLine>
                <Header as="h4" textAlign="center">
                  9
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  1
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="red" name="x" />
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  $1,399
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h2" textAlign="center">
                  PREMIUM
                </Header>
              </Table.Cell>
              <Table.Cell singleLine>
                <Header as="h4" textAlign="center">
                  12
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  2
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  $1,699
                </Header>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <div className="quarter-section">
        <div className="refer-win-money-container">
          <img
            className="img-money"
            src="https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/v1581836107/container-assets/sandra-gabriel-cwk6ei6Jo0M-unsplash.jpg"
          />
          <div className="text-money">
            <h2 className="title-money">Gana dinero recomendando</h2>
            <p className="information-money">
              Registrate y obten tu codigo de referencia con el cual recomienda
              a negocios que se den de alta usandolo y podrias ganar hasta{' '}
              <h3>$200</h3>
            </p>
          </div>
        </div>
      </div>
      <footer className="footer-page">
        <div className="footer-information-kimvex">
          <span className="kimvxe-logo">Kimvex</span>
          <a href="https://kimvex.com" className="kmv-link">
            kimvex.com
          </a>

          <a
            href="https://desarrollo.kimvex.com/"
            className="kmv-link-development"
          >
            Desarollo de paginas web
          </a>
        </div>

        <div className="contact-information-kimvex">
          <span className="contact-us">
            Si quieres contactarnos de forma directa puedes llamarnos al
            siguiente número
          </span>
          <span className="contact-us">9931076334</span>
        </div>

        <div className="social-networks-kimvex">
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item"
          >
            <Icon name="facebook official" /> Facebook
          </a>
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item twitter"
          >
            <Icon name="twitter" /> Twitter
          </a>
        </div>

        <div className="copyright">
          <span className="information-copyright">
            Copyright © 2020 Kimvex. Todos los derechos reservados. Diseñado &
            Desarrollado por Kimvex
          </span>
        </div>
      </footer>
    </div>
  );

  desktop_version = () => (
    <div className="container-complete">
      <div className="container-initial-page">
        <div className="information-of-service">
          <p className="information-text">
            Encuentra los servicios y ofertas que esten cerca de ti.
          </p>
          <div className="container-go-services">
            <NavLink className="go-services" to="/services">
              Encontrar servicios{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
            <NavLink className="go-services" to="/offers">
              Encontrar ofertas{' '}
              <img className="image-go-services" src={arrow} />
            </NavLink>
          </div>
        </div>
        <div className="container-title-inital-page">
          <h1 className="title-initial-page">Kimvex</h1>
        </div>
      </div>

      <div className="second-information-section">
        <h2 className="sub-title-information">Anuncia tu negocio</h2>
        <div className="information-step-solds">
          <Card>
            <Card.Content>
              <Card.Header>Paso 1</Card.Header>
              <Card.Meta>
                <span className="date">Registrate</span>
              </Card.Meta>
              <Card.Description>
                Ten una cuenta en la cual poder dar de alta a los negocios y
                ofertas que quieras.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header>Paso 2</Card.Header>
              <Card.Meta>
                <span className="date">Da de alta a tu negocio</span>
              </Card.Meta>
              <Card.Description>
                Agrega la información de tu negocio para que tus futuros
                clientes sepan de ti.
              </Card.Description>
            </Card.Content>
          </Card>

          <Card>
            <Card.Content>
              <Card.Header>Paso 3</Card.Header>
              <Card.Meta>
                <span className="date">Activa tu negocio</span>
              </Card.Meta>
              <Card.Description>
                Elige uno de los tres planes de anuncio para tu negocio.
              </Card.Description>
            </Card.Content>
          </Card>
        </div>
      </div>

      <div className="third-section-plans">
        <h2
          className="sub-title-information"
          style={{ width: '100px', borderColor: 'white', color: 'white' }}
        >
          Planes
        </h2>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table-plans" singleLine>
                Plan
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Meses
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Meses de regalo
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Ofertas ilimitadas
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Pagina web
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table-plans">
                Precio
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Header as="h2" textAlign="center">
                  BASICO
                </Header>
              </Table.Cell>
              <Table.Cell singleLine>
                <Header as="h4" textAlign="center">
                  6
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  1
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="red" name="x" />
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  $999
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h2" textAlign="center">
                  ELITE
                </Header>
              </Table.Cell>
              <Table.Cell singleLine>
                <Header as="h4" textAlign="center">
                  9
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  1
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="red" name="x" />
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  $1,399
                </Header>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Header as="h2" textAlign="center">
                  PREMIUM
                </Header>
              </Table.Cell>
              <Table.Cell singleLine>
                <Header as="h4" textAlign="center">
                  12
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  2
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Icon color="teal" name="check" />
              </Table.Cell>
              <Table.Cell>
                <Header as="h4" textAlign="center">
                  $1,699
                </Header>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <div className="quarter-section">
        <div className="refer-win-money-container">
          <img
            className="img-money"
            src="https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/v1581836107/container-assets/sandra-gabriel-cwk6ei6Jo0M-unsplash.jpg"
          />
          <div className="text-money">
            <h2 className="title-money">Gana dinero recomendando</h2>
            <p className="information-money">
              Registrate y obten tu codigo de referencia con el cual recomienda
              a negocios que se den de alta usandolo y podrias ganar hasta{' '}
              <h3>$200</h3>
            </p>
          </div>
        </div>
      </div>
      <footer className="footer-page">
        <div className="footer-information-kimvex">
          <span className="kimvxe-logo">Kimvex</span>
          <a href="https://kimvex.com" className="kmv-link">
            kimvex.com
          </a>
          <a
            href="https://desarrollo.kimvex.com/"
            className="kmv-link-development"
          >
            Desarollo de paginas web
          </a>
        </div>

        <div className="contact-information-kimvex">
          <span className="contact-us">
            Si quieres contactarnos de forma directa puedes llamarnos al
            siguiente número
          </span>
          <span className="contact-us">9931076334</span>
        </div>

        <div className="social-networks-kimvex">
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item"
          >
            <Icon name="facebook official" /> Facebook
          </a>
          <a
            target="_blink"
            href="https://www.facebook.com/kimvex"
            className="social-networks-item twitter"
          >
            <Icon name="twitter" /> Twitter
          </a>
        </div>

        <div className="copyright">
          <span className="information-copyright">
            Copyright © 2020 Kimvex. Todos los derechos reservados. Diseñado &
            Desarrollado por Kimvex
          </span>
        </div>
      </footer>
    </div>
  );

  render() {
    return (
      <div>
        <Responsive maxWidth={320}>{this.mini_movile()}</Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.desktop_version()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.tablet_version()}
        </Responsive>
        <Responsive {...Responsive.onlyMobile} minWidth={321}>
          {this.movile_version()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = () => {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IntialPage)
);
