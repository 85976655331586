import { createReducer } from '../store';

const loginReducer = createReducer(
  {
    token: null,
    login_error: false
  },
  {
    LOGIN_SUCCESS(state, action) {
      const {
        data: { token }
      } = action.payload;

      return {
        ...state,
        token
      };
    },
    LOGIN_FAIL(state, action) {
      return {
        ...state,
        login_error: true
      };
    },
    CHANGE_STATUS_ERROR(state, action) {
      const { login_error } = action.payload;
      return {
        ...state,
        login_error
      };
    }
  }
);

export default loginReducer;
