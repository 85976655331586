export const save_offer = (token, data) => ({
  type: 'SAVE_OFFER',
  payload: {
    client: 'default',
    request: {
      url: '/shop/offers',
      method: 'post',
      headers: {
        token
      },
      data
    }
  }
});

export const get_my_offers = (token, shop_id, page = 0, limit = 5) => ({
  type: 'GET_MY_OFFERS',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/offers?limit=${limit}&page=${page}`,
      method: 'get',
      headers: {
        token
      }
    }
  }
});

export const get_my_offers_last = (token, shop_id, page = 0, limit = 5) => ({
  type: 'GET_MY_OFFERS_LAST',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/offers?limit=${limit}&page=${page}`,
      method: 'get',
      headers: {
        token
      }
    }
  }
});

export const get_my_offers_update = (token, shop_id, page = 0, limit = 5) => ({
  type: 'GET_MY_OFFERS_UPDATE',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/offers?limit=${limit}&page=${page}`,
      method: 'get',
      headers: {
        token
      }
    }
  }
});

export const update_offer = (token, offer_id, data) => ({
  type: 'UPDATE_OFFER',
  payload: {
    client: 'default',
    request: {
      url: `/shop/offers/${offer_id}`,
      method: 'put',
      headers: {
        token
      },
      data
    }
  }
});

export const my_nearby_offeres = (
  lat,
  lon,
  limit,
  last_offer_id,
  minDistance
) => ({
  type: 'NEARBY_OFFERS',
  payload: {
    client: 'default',
    request: {
      url: `/shops/offers/${lat}/${lon}?limit=${limit}&last_offer_id=${last_offer_id}&minDistance=${minDistance}`,
      method: 'get',
      headers: {
        token: 'mission_all'
      }
    }
  }
});

export const set_offers = () => ({
  type: 'UPDATE_OFFERS_SET',
  payload: {}
});
