import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

import BodyShop from './body';

class EditShop extends Component {
  render() {
    return (
      <Container>
        <BodyShop key={Date.now()} title_service="Editar mi servicio" />
      </Container>
    );
  }
}

export default EditShop;
