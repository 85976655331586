import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Responsive, Segment, Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from '../../redux/actions/Login';

class MenuCompoment extends Component {
  static propTypes = {};

  state = { activeItem: 'Kimvex', open_menu: false };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  openMenu = () => {
    this.setState({
      open_menu: true,
    });

    document.getElementById('html-hidden').classList.add('remove-overflow');
  };

  close_menu = () => {
    this.setState({
      open_menu: false,
    });

    document.getElementById('html-hidden').classList.remove('remove-overflow');
  };

  render() {
    const { token, logout, history } = this.props;
    const { open_menu } = this.state;
    console.log(token);
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <div
            style={{
              marginBottom: '1rem',
              fontSize: '1.6rem',
              width: '100%',
              padding: '1rem 0rem',
              borderBottom: '1px solid rgba(34, 36, 38, 0.15)',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ width: '50px' }} onClick={this.openMenu}>
              <Icon
                disabled
                name="bars"
                style={{ marginRight: '0.8rem', cursor: 'pointer' }}
              />
            </div>
          </div>
          {open_menu && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                backgroundColor: '#ffffffde',
                zIndex: 1100,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  marginBottom: '1rem',
                  fontSize: '1.6rem',
                  width: '100%',
                  padding: '1rem 0rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ width: '50px' }} onClick={this.close_menu}>
                  <Icon
                    disabled
                    name="close"
                    style={{ marginRight: '0.8rem', cursor: 'pointer' }}
                  />
                </div>
              </div>
              <ul className="menu-responsive">
                <Menu.Item
                  className="title-kimvex"
                  onClick={this.close_menu}
                  as={Link}
                  to="/"
                  active
                >
                  Kimvex
                </Menu.Item>
                <Menu.Item onClick={this.close_menu} as={Link} to="/services">
                  Servicios
                </Menu.Item>
                <Menu.Item onClick={this.close_menu} as={Link} to="/offers">
                  Ofertas
                </Menu.Item>
                <Menu.Item onClick={this.close_menu} as={Link} to="/new-shop">
                  Crear Servicio
                </Menu.Item>
                {token && (
                  <Menu.Item onClick={this.close_menu} as={Link} to="/profile">
                    Mi perfil
                  </Menu.Item>
                )}
                {token && (
                  <Menu.Item
                    onClick={() =>
                      logout(token).then((result) => {
                        this.close_menu();
                        if (result.type === 'SUBMIT_LOGOUT_REQUEST_SUCCESS') {
                          history.push('/login');
                        }
                      })
                    }
                  >
                    Salir
                  </Menu.Item>
                )}

                {!token && (
                  <Menu.Item onClick={this.close_menu} as={Link} to="/login">
                    Iniciar sesion
                  </Menu.Item>
                )}

                {!token && (
                  <Menu.Item onClick={this.close_menu} as={Link} to="/register">
                    Registrarse
                  </Menu.Item>
                )}
              </ul>
            </div>
          )}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          <Menu pointing secondary size="small" color="teal">
            <Menu.Item className="title-kimvex" as={Link} to="/" active>
              Kimvex
            </Menu.Item>
            <Menu.Item as={Link} to="/services">
              Servicios
            </Menu.Item>
            <Menu.Item as={Link} to="/offers">
              Ofertas
            </Menu.Item>
            <Menu.Item as={Link} to="/new-shop">
              Crear Servicio
            </Menu.Item>
            <Menu.Menu position="right">
              {token && (
                <Menu.Item as={Link} to="/profile">
                  Mi perfil
                </Menu.Item>
              )}
              {token && (
                <Menu.Item
                  onClick={() =>
                    logout(token).then((result) => {
                      if (result.type === 'SUBMIT_LOGOUT_REQUEST_SUCCESS') {
                        history.push('/login');
                      }
                    })
                  }
                >
                  Salir
                </Menu.Item>
              )}

              {!token && (
                <Menu.Item as={Link} to="/login">
                  Iniciar sesion
                </Menu.Item>
              )}

              {!token && (
                <Menu.Item as={Link} to="/register">
                  Registrarse
                </Menu.Item>
              )}
            </Menu.Menu>
          </Menu>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <Menu pointing secondary size="large" color="teal">
            <Menu.Item className="title-kimvex" as={Link} to="/" active>
              Kimvex
            </Menu.Item>
            <Menu.Item as={Link} to="/services">
              Servicios
            </Menu.Item>
            <Menu.Item as={Link} to="/offers">
              Ofertas
            </Menu.Item>
            <Menu.Item as={Link} to="/new-shop">
              Crear Servicio
            </Menu.Item>
            <Menu.Menu position="right">
              {token && (
                <Menu.Item as={Link} to="/profile">
                  Mi perfil
                </Menu.Item>
              )}
              {token && (
                <Menu.Item
                  onClick={() =>
                    logout(token).then((result) => {
                      if (result.type === 'SUBMIT_LOGOUT_REQUEST_SUCCESS') {
                        history.push('/login');
                      }
                    })
                  }
                >
                  Salir
                </Menu.Item>
              )}

              {!token && (
                <Menu.Item as={Link} to="/login">
                  Iniciar sesion
                </Menu.Item>
              )}

              {!token && (
                <Menu.Item as={Link} to="/register">
                  Registrarse
                </Menu.Item>
              )}
            </Menu.Menu>
          </Menu>
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer: { token } }) => ({ token });

const mapDispatchToProps = {
  logout,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuCompoment)
);
