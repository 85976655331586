import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoginAuth, ChangeStatusError } from '../../redux/actions/Login';
import { GetProfile } from '../../redux/actions/Profile';

class Login extends Component {
  static propTypes = {};
  state = {
    email: '',
    password: ''
  };

  handleEmail = e => {
    this.setState({
      email: e.target.value
    });
  };

  handlePassword = e => {
    this.setState({
      password: e.target.value
    });
  };

  submitLogin = () => {
    const {
      GetProfile,
      LoginAuth,
      ChangeStatusError,
      login_error
    } = this.props;
    if (this.state.email.trim() && this.state.password.trim()) {
      LoginAuth(this.state.email.trim(), this.state.password.trim()).then(
        data => {
          const {
            payload: { data: { token } } = { data: { token: '' } }
          } = data;
          if (token) {
            GetProfile(token);
          }
        }
      );
      setTimeout(() => {
        if (login_error) {
          ChangeStatusError(false);
          return;
        }
      }, 3000);
    }
  };

  render() {
    const { login_error } = this.props;

    return (
      <Grid
        textAlign="center"
        style={{ height: '70vh', width: '100%', margin: 0 }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 430, width: '100%' }}>
          <Header as="h1" color="teal" textAlign="center">
            Inicio de sesion
          </Header>
          <Segment stacked>
            <Form size="large">
              <Form.Input
                fluid
                type="email"
                icon="user"
                onChange={this.handleEmail}
                iconPosition="left"
                placeholder="Correo"
                required
              />
              <Form.Input
                fluid
                icon="lock"
                onChange={this.handlePassword}
                iconPosition="left"
                placeholder="Contraseña"
                type="password"
                required
              />
              <Button
                onClick={this.submitLogin}
                color="teal"
                fluid
                size="large"
              >
                Entrar
              </Button>
            </Form>
            {login_error && (
              <Message
                error
                header="Falló iniciar sesion"
                content="Tu usuario o contraseña son incorrectos."
              />
            )}
          </Segment>
          <Message>
            ¿Aun no tienes cuenta?{' '}
            <a href="/register" style={{ color: 'teal' }}>
              Registrar
            </a>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({ loginReducer: { token, login_error } }) => ({
  token,
  login_error
});

const mapDispatchToProps = {
  LoginAuth,
  ChangeStatusError,
  GetProfile
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
