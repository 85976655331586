import React, { Component } from 'react';
import {
  Button,
  Modal,
  Form,
  TextArea,
  Grid,
  Image,
  Label
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  save_offer,
  get_my_offers,
  update_offer,
  get_my_offers_last,
  get_my_offers_update
} from '../../redux/actions/Offers';
import { upload_images } from '../../redux/actions/Shop';

class OfferModal extends Component {
  setProps = () => ({
    title: this.props.title ? this.props.title : '',
    description: this.props.description ? this.props.description : '',
    date_end: this.props.date_end ? this.props.date_end.slice(0, 10) : '',
    date_init: this.props.date_init ? this.props.date_init.slice(0, 10) : '',
    image_url: this.props.image_url ? this.props.image_url : '',
    active: this.props.active ? this.props.active : false,
    offers_id: this.props.offers_id ? this.props.offers_id : null,
    image_preview: this.props.image_url ? this.props.image_url : ''
  });

  state = this.setProps();

  handleSetInputsValues = (e, type) => {
    switch (type) {
      case 'title':
        this.setState({
          title: e.target.value
        });
        break;
      case 'description':
        this.setState({
          description: e.target.value
        });
        break;
      case 'date_end':
        console.log(e.target.value, "enma", e.target)
        this.setState({
          date_end: e.target.value
        });
        break;
      case 'date_init':
        this.setState({
          date_init: e.target.value
        });
        break;

      default:
        break;
    }
  };

  change_state_image_logo = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          image_preview: reader.result
        });
      };

      reader.readAsDataURL(file);

      const formData = new FormData();

      formData.append('file', file);

      this.props.upload_images(formData, this.props.token).then(response => {
        if (response.type === 'UPLOAD_IMAGES_SUCCESS') {
          const {
            payload: {
              data: { result }
            }
          } = response;
          this.setState({ image_url: result });
        }
      });
    } else {
      alert('Error al selecionar la imagen');
    }
  };

  clear_url = () => {
    this.setState({
      image_preview: '',
      image_url: ''
    });
  };

  save_offer_action = active => {
    const {
      title,
      description,
      date_end,
      date_init,
      image_url,
      offers_id
    } = this.state;
    const {
      token,
      shop_id,
      update_offer,
      save_offer,
      get_my_offers_update,
      get_my_offers_last,
      offers
    } = this.props;
    const data_set = {};

    if (title.trim()) {
      data_set.title = title.trim();
    }
    if (description.trim()) {
      data_set.description = description.trim();
    }
    if (date_end) {
      data_set.date_end = date_end;
    }
    if (date_init) {
      data_set.date_init = date_init;
    }
    if (image_url) {
      data_set.image_url = image_url;
    }

    data_set.shop_id = shop_id;
    if (!offers_id) {
      save_offer(token, data_set).then(response => {
        if (response.type === 'SAVE_OFFER_SUCCESS') {
          if (active) {
            update_offer(token, response.payload.data.offer_id, {
              shop_id,
              active: 1
            }).then(result => {
              if (result.type === 'UPDATE_OFFER_SUCCESS') {
                get_my_offers_update(token, shop_id, 0, offers);
              }
            });
          }
          get_my_offers_last(token, shop_id);
          this.props.close_offer();
        }
      });
    } else {
      if (active) {
        data_set.active = 1;
      }
      update_offer(token, offers_id, data_set).then(result => {
        if (result.type === 'UPDATE_OFFER_SUCCESS') {
          get_my_offers_update(token, shop_id, 0, offers);
          this.props.close_offer();
        }
      });
    }
  };

  active_offer = () => {
    this.save_offer_action(true);
  };

  componentDidMount = () => {
    this.setState(this.setProps());
  };

  desactive_offer = offers_id => {
    const { update_offer, token, shop_id, close_offer, offers } = this.props;
    update_offer(token, offers_id, {
      active: 0,
      shop_id
    }).then(result => {
      if (result.type === 'UPDATE_OFFER_SUCCESS') {
        get_my_offers_update(token, shop_id, 0, offers);
        close_offer();
      }
    });
  };

  render() {
    const open = this.props.show_modal_offer;

    let {
      offers_id,
      title,
      description,
      date_end,
      date_init,
      active,
      image_preview
    } = this.state;
    console.log(this.state, '---');

    if (image_preview && image_preview.split('http')) {
      const split_image = image_preview.split('/upload/');
      image_preview = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_200/${split_image[1]}`;
    }

    return (
      <Modal open={open} onClose={this.props.close_offer} size="small">
        <Modal.Header>Offerta</Modal.Header>
        <Modal.Content>
          <Form size="large">
            <Form.Input
              fluid
              icon="bullhorn"
              iconPosition="left"
              placeholder="Nombre de la oferta"
              value={title}
              onChange={e => this.handleSetInputsValues(e, 'title')}
            />
            <TextArea
              value={description}
              placeholder="Descripción de la oferta"
              style={{ minHeight: 100, marginBottom: '0.5rem' }}
              onChange={e => this.handleSetInputsValues(e, 'description')}
            />
            <Form.Input
              fluid
              iconPosition="left"
              label="Subir imagen"
              type="file"
              onChange={this.change_state_image_logo}
            />
            <Grid style={{ marginBottom: '0.5rem' }} doubling columns={2}>
              <Grid.Column>
                <Image size="small" src={image_preview} />
                {image_preview && (
                  <Button
                    style={{ marginTop: '0.2rem' }}
                    size="mini"
                    onClick={this.clear_url}
                  >
                    Eliminar
                  </Button>
                )}
              </Grid.Column>
            </Grid>
            <Form.Group widths="equal" Tex>
              <div style={{ width: '50%' }}>
                <Label pointing="below">Fecha de inicio</Label>
                <Form.Input
                  required
                  icon="calendar"
                  iconPosition="left"
                  type="date"
                  value={date_init.slice(0, 10)}
                  onChange={e => this.handleSetInputsValues(e, 'date_init')}
                />
              </div>
              <div style={{ width: '50%' }}>
                <Label pointing="below">Fecha de finalización</Label>
                <Form.Input
                  required
                  icon="calendar"
                  iconPosition="left"
                  type="date"
                  value={date_end.slice(0, 10)}
                  onChange={e => {
                      console.log(e, "end")
                      this.handleSetInputsValues(e, 'date_end')
                    }
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group style={{ marginTop: '0.5rem' }}>
            <Button
              size="mini"
              content="Guardar"
              color="blue"
              onClick={() => this.save_offer_action()}
            />
            {!active && offers_id && (
              <Button
                size="mini"
                content="Activar"
                color="teal"
                onClick={() => this.save_offer_action(true)}
              />
            )}

            {active === 1 && (
              <Button
                size="mini"
                onClick={() => this.desactive_offer(offers_id)}
                content="Desactivar"
                color="red"
              />
            )}

            <Button
              size="mini"
              content="Cerrar"
              onClick={this.props.close_offer}
            />
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ loginReducer: { token } }) => ({
  token
});

const mapDispatchToProps = {
  save_offer,
  upload_images,
  get_my_offers,
  update_offer,
  get_my_offers_last,
  get_my_offers_update
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferModal)
);
