export const LoginAuth = (email, password) => ({
  type: 'LOGIN',
  payload: {
    client: 'default',
    request: {
      url: '/user/login',
      method: 'post',
      data: {
        email,
        password,
      },
    },
  },
});

export const ChangeStatusError = (login_error) => ({
  type: 'CHANGE_STATUS_ERROR',
  payload: {
    login_error,
  },
});

export const logout = (token) => ({
  type: 'SUBMIT_LOGOUT_REQUEST',
  payload: {
    client: 'default',
    request: {
      url: '/user/logout',
      method: 'post',
      headers: {
        token,
      },
    },
  },
});
