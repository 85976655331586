import { createReducer } from '../store';

const shopReducer = createReducer(
  {
    shop_name: '',
    address: '',
    phone: '',
    phone2: '',
    description: '',
    cover_image: '',
    logo: '',
    accept_card: '0',
    list_cards: [],
    list_images: [],
    type_s: '',
    lat: '',
    lon: '',
    service_type: '',
    error_save: false,
    LUN: 'CLOSE',
    MAR: 'CLOSE',
    MIE: 'CLOSE',
    JUE: 'CLOSE',
    VIE: 'CLOSE',
    SAB: 'CLOSE',
    DOM: 'CLOSE',
    list_shop_location: [],
    services: [],
    score_shop: 0,
    comments: [],
    my_score_set: 0,
    status: '',
    new_comment: false,
    last_distance: 0.0,
    last_shop_id: 0,
    sub_service: [],
  },
  {
    UPDATE_STATUS(state, action) {
      return {
        ...state,
      };
    },
    SAVE_PROPERTY_SUCCESS(state, action) {
      return {
        ...state,
        shop_name: '',
        address: '',
        phone: '',
        phone2: '',
        description: '',
        cover_image: '',
        logo: '',
        accept_card: '0',
        list_cards: [],
        list_images: [],
        type_s: '',
        lat: '',
        lon: '',
        service_type: '',
        error_save: false,
        LUN: 'CLOSE',
        MAR: 'CLOSE',
        MIE: 'CLOSE',
        JUE: 'CLOSE',
        VIE: 'CLOSE',
        SAB: 'CLOSE',
        DOM: 'CLOSE',
      };
    },
    SAVE_PROPERTY_FAIL(state, action) {
      return {
        ...state,
        error_save: true,
      };
    },
    GET_PROPERTY_SUCCESS(state, action) {
      const {
        data,
        data: {
          accept_card,
          service_type_id,
          sub_service_type_id,
          images,
          status,
        },
      } = action.payload;
      delete state.my_score_set;
      console.log(images, 'imagenes');
      return {
        ...state,
        ...data,
        accept_card: accept_card == '1' ? true : false,
        type_s: service_type_id,
        service_type: sub_service_type_id,
        list_images: images,
        status: status,
      };
    },
    RESET_STATES_SHOP(state, action) {
      return {
        ...state,
        shop_name: '',
        address: '',
        phone: '',
        phone2: '',
        description: '',
        cover_image: '',
        logo: '',
        accept_card: false,
        list_cards: [],
        list_images: [],
        type_s: '',
        lat: '',
        lon: '',
        service_type: '',
        error_save: false,
        LUN: 'CLOSE',
        MAR: 'CLOSE',
        MIE: 'CLOSE',
        JUE: 'CLOSE',
        VIE: 'CLOSE',
        SAB: 'CLOSE',
        DOM: 'CLOSE',
      };
    },
    // GET_SHOPS_LOCATION_SUCCESS(state, action) {
    //   let {
    //     data: { shop = [], last_shop_id, last_distance }
    //   } = action.payload;
    //   const { list_shop_location = [] } = state;

    //   if (list_shop_location.length > 0) {
    //     shop.shift();
    //   } else {
    //     shop.pop();
    //   }

    //   return {
    //     ...state,
    //     list_shop_location: shop,
    //     last_shop_id,
    //     last_distance
    //   };
    // },
    SET_EMPTY_SHOPS_EMPTY(state) {
      return {
        ...state,
      };
    },
    GET_SERVICES_SUCCESS(state, action) {
      const {
        data: { services },
      } = action.payload;
      return {
        ...state,
        services,
      };
    },
    GET_COMMENTS_SHOP_SUCCESS(state, action) {
      const { comments = [], new_comment = false } = state;
      if (state.my_score_set === 0) {
        delete state.my_score_set;
      }
      let comments_concat;
      const { data = [] } = action.payload;

      if (new_comment) {
        comments_concat = [data[0]].concat(comments);
      } else {
        comments_concat = comments.concat(data);
      }

      return {
        ...state,
        comments: comments_concat,
        new_comment: false,
      };
    },
    COMMENT_SHOP_SUCCESS(state, action) {
      return {
        ...state,
        new_comment: true,
      };
    },
    SET_COMMENTS_EMPTY(state) {
      delete state.my_score_set;
      return {
        ...state,
        comments: [],
      };
    },
    SET_RATING_SUCCESS(state, action) {
      return {
        ...state,
      };
    },
    GET_MY_SET_SCORE_SUCCESS(state, action) {
      const {
        data: { score = 0 },
      } = action.payload;
      return {
        ...state,
        my_score_set: score,
      };
    },
    UPDATE_RATING_SUCCESS(state, action) {
      return {
        ...state,
      };
    },
    GET_SUB_SERVICES_SUCCESS(state, action) {
      const {
        data: { sub_service },
      } = action.payload;
      return {
        ...state,
        sub_service,
      };
    },
  }
);

export default shopReducer;
