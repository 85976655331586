import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-less/semantic.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import OpenPay from 'openpay-js';
// Service_sectorial_MA20A3

import { makeStore } from './redux/store';

document.OpenPay = OpenPay;

const { store, persistor } = makeStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router basename="/">
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
