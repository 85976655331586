export const GetProfile = (token) => ({
  type: 'GET_PROFILE',
  payload: {
    client: 'default',
    request: {
      url: '/user/profile',
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const UpdateProfile = (token, data) => ({
  type: 'UPDATE_PROFILE',
  payload: {
    client: 'default',
    request: {
      url: '/user/update/profile',
      method: 'put',
      headers: {
        token,
      },
      data,
    },
  },
});

export const update_status = () => ({
  type: 'UPDATE_STATUS',
  payload: {},
});

export const update_password = (new_password, password, token) => ({
  type: 'UPDATE_PASSWORD',
  payload: {
    client: 'default',
    request: {
      url: '/user/update/profile',
      method: 'put',
      headers: {
        token,
      },
      data: {
        new_password,
        password,
      },
    },
  },
});

export const upload_profile = (data, token) => ({
  type: 'UPLOAD_IMAGE_PROFILE',
  payload: {
    client: 'default',
    request: {
      url: '/images/avatar',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data',
        token,
      },
      data,
    },
  },
});

export const change_image_profile = (image, token) => ({
  type: 'CHANGE_IMAGE_PROFILE',
  payload: {
    client: 'default',
    request: {
      url: '/user/update/profile',
      method: 'put',
      headers: {
        token,
      },
      data: {
        image_url: image,
      },
    },
  },
});

export const get_my_shops = (token, limit = 10, page = 0) => ({
  type: 'GET_MY_SHOPS',
  payload: {
    client: 'default',
    request: {
      url: `/profile/shops?limit=${limit}&page=${page}`,
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const get_update_my_shops = (token, limit = 10, page = 0) => ({
  type: 'GET_UPDATE_MY_SHOPS',
  payload: {
    client: 'default',
    request: {
      url: `/profile/shops?limit=${limit}&page=${page}`,
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const get_reffers_success = (token) => ({
  type: 'GET_REFFERS',
  payload: {
    client: 'default',
    request: {
      url: '/user/referrals',
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const get_reffers_fail = (token) => ({
  type: 'GET_REFFERS_FAIL',
  payload: {
    client: 'default',
    request: {
      url: '/user/refferals_fail',
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const get_win_month = (token) => ({
  type: 'GET_WIN_MONTH',
  payload: {
    client: 'default',
    request: {
      url: '/user/earned_referrals_month',
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const get_win_all = (token) => ({
  type: 'GET_WIN_ALL',
  payload: {
    client: 'default',
    request: {
      url: '/user/earned_referrals',
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const auth_code = (code, token) => ({
  type: 'AUTH_CODE',
  payload: {
    client: 'default',
    request: {
      url: `/code/auth?reffer_code=${code}`,
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const get_page_info = (token, shop_id) => ({
  type: 'GET_PAGE_INFO',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/page`,
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const update_page = (data, shop_id, page_id, token) => ({
  type: 'UPDATE_PAGE',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/update_page/${page_id}`,
      method: 'put',
      headers: {
        token,
      },
      data,
    },
  },
});

export const active_page = (shop_id, page_id, token) => ({
  type: 'ACTIVE_PAGE',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/active_page/${page_id}`,
      method: 'put',
      headers: {
        token,
      },
    },
  },
});

export const deactivate_page = (shop_id, page_id, token) => ({
  type: 'DEACTIVE_PAGE',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/deactivate_page/${page_id}`,
      method: 'put',
      headers: {
        token,
      },
    },
  },
});

export const validate_subdomain = (shop_id, subdomain, token) => ({
  type: 'VALIDATE_PAGE',
  payload: {
    client: 'default',
    request: {
      url: `/shop/${shop_id}/validate_subdomain?subdomain=${subdomain}`,
      method: 'get',
      headers: {
        token,
      },
    },
  },
});

export const set_my_shops = () => ({
  type: 'SET_MY_SHOPS_EMPTY',
  payload: {},
});
