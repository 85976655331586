import React, { Component } from 'react';
import { Card, Image, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { set_location } from '../../redux/actions/General';
import { my_nearby_offeres } from '../../redux/actions/Offers';

class ListOffers extends Component {
  static propTypes = {};

  state = {
    last_offer: ['0'],
    minDistance: 0.0,
    new_find: true,
    nearby_offers: []
  };

  reinsert_position = () => {
    const { set_location } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        ({ coords: { latitude, longitude } }) => {
          set_location(latitude, longitude);
        },
        error => console.log(error)
      );
    } else {
    }
  };

  get_offers = () => {
    const { my_nearby_offeres, my_lat, my_lon } = this.props;
    const { last_offer = [], minDistance } = this.state;

    let last_offers = '';

    last_offer.forEach(i => {
      if (i.length > 0) {
        last_offers = `${i},`;
      } else {
        last_offers = i;
      }
    });

    my_nearby_offeres(my_lat, my_lon, 10, last_offer, minDistance).then(
      response => {
        if (response.type === 'NEARBY_OFFERS_SUCCESS') {
          const {
            data: { last_offer_id, last_distance, offers = [] }
          } = response.payload;

          if (last_offer_id && last_distance) {
            this.setState({
              last_offer: this.state.last_offer.concat(offers.map(offer => offer.offer_id)),
              minDistance: last_distance,
              nearby_offers: this.state.nearby_offers.concat(offers),
              new_find: false
            });
          }
        }
      }
    );
  };

  componentWillMount = () => {
    this.reinsert_position();
  };

  componentDidMount = () => {
    this.get_offers();
  };

  offer_cards = type => {
    const { nearby_offers = [] } = this.state;
    return (
      <Card.Group style={{ margin: '0rem' }} centered>
        {nearby_offers.map(
          (
            {
              title,
              image_url,
              description,
              shop_name,
              distance,
              date_init,
              date_end
            },
            key
          ) => {
            let measure = 'km';

            if (distance < 1000) {
              measure = 'm';
            }

            if (distance > 1000) {
              measure = 'km';
              distance = distance.toFixed(0) / 1000;
            }

            const split_img = image_url.split('/upload/');
            let image_standar = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto,w_300,h_300/${split_img[1]}`;

            return (
              <Card
                style={{
                  width:
                    type == 'computer'
                      ? '250px'
                      : type == 'tablet'
                      ? '45%'
                      : '85%'
                }}
                key={key}
              >
                <Image
                  style={{
                    width:
                      type == 'computer'
                        ? '250px'
                        : type == 'tablet'
                        ? '100%'
                        : '100%',
                    height: '170px !important'
                  }}
                  src={image_standar}
                  wrapped
                  ui={false}
                />
                <Card.Content>
                  <Card.Header
                    style={type == 'mobile' ? { fontSize: '2rem' } : {}}
                    className="text-color-kimvex"
                  >
                    {title}
                  </Card.Header>
                  <Card.Description
                    style={type == 'mobile' ? { fontSize: '1.3rem' } : {}}
                  >
                    {description}
                  </Card.Description>
                  <Card.Meta
                    style={type == 'mobile' ? { fontSize: '1.2rem' } : {}}
                  >
                    {shop_name}
                  </Card.Meta>
                </Card.Content>
                <Card.Content
                  style={type == 'mobile' ? { fontSize: '1.2rem' } : {}}
                  extra
                  textAlign="center"
                >
                  <span className="two">
                    Desde {date_init.slice(0, 10)} - Hasta{' '}
                    {date_end.slice(0, 10)}
                  </span>
                </Card.Content>
                <Card.Content
                  style={type == 'mobile' ? { fontSize: '1.2rem' } : {}}
                  extra
                  textAlign="center"
                >
                  <span className="text-color-kimvex">
                    {distance.toFixed(2)} {measure}
                  </span>
                </Card.Content>
              </Card>
            );
          }
        )}
      </Card.Group>
    );
  };

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.offer_cards('mobile')}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.offer_cards('tablet')}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.offer_cards('computer')}
        </Responsive>
        <div
          style={{ width: '100%', textAlign: 'center', padding: '2rem 0rem' }}
        >
          <span
            onClick={this.get_offers}
            style={{ color: 'teal', cursor: 'pointer', fontSize: '1.2rem' }}
          >
            Mostrar más...
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  offersReducer: { nearby_offers },
  generalReducer: { my_lat, my_lon }
}) => ({
  nearby_offers,
  my_lat,
  my_lon
});

const mapDispatchToProps = {
  my_nearby_offeres,
  set_location
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListOffers)
);
