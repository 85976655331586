import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';

import Information from './information';
import MyShopsList from './my-shops-list';
import References from './referenciados';

class Profile extends Component {
  render() {
    return (
      <Container>
        <Information />
        <References />
        <MyShopsList />
      </Container>
    );
  }
}

export default Profile;
