export const pay_shop = data => ({
  type: 'PAY_SERVICE',
  payload: {
    client: 'pay_verify',
    request: {
      url: '/charge',
      method: 'post',
      data
    }
  }
});
