import React, { Component } from 'react';
import { Segment, Grid, Message, Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
  RegisterUser,
  RegisterChangeError,
  UpdateEmailRegister
} from '../../redux/actions/Register';

const options = [
  { key: 'm', text: 'Hombre', value: 'male' },
  { key: 'f', text: 'Mujer', value: 'female' }
];

class Register extends Component {
  state = {
    name: '',
    email: '',
    password1: '',
    password2: '',
    phone: '',
    birthday: '',
    gender: '',
    term_cond: false,
    error_password: false,
    error_fields: false
  };

  handleInputValues = (e, type) => {
    switch (type) {
      case 'name':
        this.setState({ name: e.target.value.trim() });
        break;
      case 'email':
        this.setState({ email: e.target.value.trim() });
        this.props.UpdateEmailRegister(e.target.value.trim());
        break;
      case 'password1':
        this.setState({ password1: e.target.value.trim() });
        break;
      case 'password2':
        this.setState({ password2: e.target.value.trim() });
        break;
      case 'phone':
        this.setState({ phone: e.target.value.trim() });
        break;
      case 'birthday':
        this.setState({ birthday: e.target.value.trim() });
        break;
      case 'gender':
        this.setState({ gender: e.value });
        break;
      case 'term_cond':
        this.setState({ term_cond: e.checked });
        break;
      default:
        break;
    }
  };

  submitRegister = () => {
    const {
      name,
      email,
      password1,
      password2,
      phone,
      birthday,
      gender,
      term_cond
    } = this.state;
    if (
      name &&
      email &&
      password1 &&
      password2 &&
      phone &&
      birthday &&
      gender &&
      term_cond !== false
    ) {
      if (password1 !== password2) {
        this.setState({
          error_password: true
        });

        setTimeout(() => {
          this.setState({
            error_password: false
          });
        }, 3000);
        return;
      }

      this.props.RegisterUser({
        fullname: name,
        email,
        password: password1,
        phone: parseInt(phone),
        age: birthday,
        gender
      });

      setTimeout(() => {
        if (this.props.error_register) {
          this.props.RegisterChangeError(false);
          return;
        }

        this.props.history.push('/verification_code');
      }, 5000);
    } else {
      this.setState({
        error_fields: true
      });

      setTimeout(() => {
        this.setState({
          error_fields: false
        });
      }, 3000);
    }
  };

  render() {
    console.log(this.props.error_register);
    const { error_password, error_fields } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: '80vh', width: '100%', margin: 0 }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 430, width: '100%' }}>
          <Segment stacked>
            <Form size="large">
              <Form.Input
                required
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Nombre"
                onChange={e => this.handleInputValues(e, 'name')}
              />
              <Form.Input
                required
                fluid
                icon="at"
                iconPosition="left"
                placeholder="Correo"
                type="email"
                onChange={e => this.handleInputValues(e, 'email')}
              />
              <Form.Input
                required
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Contraseña"
                type="password"
                onChange={e => this.handleInputValues(e, 'password1')}
              />
              <Form.Input
                required
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Repetir contraseña"
                type="password"
                onChange={e => this.handleInputValues(e, 'password2')}
              />
              <Form.Input
                required
                fluid
                icon="phone"
                iconPosition="left"
                placeholder="phone"
                type="number"
                onChange={e => this.handleInputValues(e, 'phone')}
              />
              <Form.Input
                required
                fluid
                icon="birthday cake"
                iconPosition="left"
                placeholder="Fecha de nacimiento"
                type="date"
                onChange={e => this.handleInputValues(e, 'birthday')}
              />
              <Form.Select
                required
                fluid
                options={options}
                placeholder="Genero"
                onChange={(e, data) => this.handleInputValues(data, 'gender')}
              />
              <Form.Checkbox
                fluid
                required
                label="Acepto los terminos y condiciones"
                onChange={(e, data) =>
                  this.handleInputValues(data, 'term_cond')
                }
              />
              <Button
                onClick={this.submitRegister}
                color="teal"
                fluid
                size="large"
              >
                Registrar
              </Button>
            </Form>
            {error_password && (
              <Message
                error
                header="Error validación de contraseña"
                content="Las contraseña no coinciden."
              />
            )}

            {error_fields && (
              <Message
                error
                header="Error con los campos"
                content="Los campos no pueden estar vacíos."
              />
            )}

            {this.props.error_register && (
              <Message error header="Error al registrarse">
                <Message.Content>
                  Ocurrio un error al registrarse se puede deber a que el correo
                  ya esta siendo usado por otro usuario. Si ya te registraste y
                  tienes un código de verificación ve a la siguiente dirección
                  <br />
                  <a
                    onClick={() =>
                      this.props.history.push('/verification_code')
                    }
                    style={{ color: 'teal' }}
                  >
                    Verificar cuenta
                  </a>
                </Message.Content>
              </Message>
            )}
          </Segment>
          <Message>
            Ya tienes cuenta{' '}
            <a href="/login" style={{ color: 'teal' }}>
              Iniciar sesion
            </a>
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = ({ registerReducer: { error_register } }) => ({
  error_register
});

const mapDispatchToProps = {
  RegisterUser,
  RegisterChangeError,
  UpdateEmailRegister
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
