import loginReducer from './Login';
import registerReducer from './Register';
import profileReducer from './Profile';
import shopReducer from './Shop';
import generalReducer from './General';
import offersReducer from './Offers';

export default {
  loginReducer,
  registerReducer,
  profileReducer,
  shopReducer,
  generalReducer,
  offersReducer
};
