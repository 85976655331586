import React, { Component } from 'react';
import {
  Segment,
  Grid,
  Button,
  Form,
  TextArea,
  Image,
  // Search,
  Message,
  Header,
  Divider,
  Label,
  Modal,
  Icon,
  List,
} from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import TimeInput from 'react-time-input';
import Autocomplete from 'react-google-autocomplete';

import { get_list_services, get_sub_services } from '../../redux/actions/Shop';

import {
  upload_cover,
  upload_logo,
  upload_images,
  save_shop,
  get_shop,
  reset_state_shop,
  delete_images_server,
} from '../../redux/actions/Shop';

const fields_translate = {
  shop_name: 'Nombre del servicio',
  cover_image: 'Imagen de portada',
  address: 'Dirección',
  description: 'Descripcion',
  type_s: 'Servicio',
  service_type: 'Tipo de servicio',
  logo: 'Logo',
};

class BodyForm extends Component {
  initialState = () => ({
    center: {
      lat: this.props.lat,
      lng: this.props.lon,
    },
    marker: {
      lat: this.props.lat,
      lng: this.props.lon,
    },
    zoom: 18,
    draggable: true,
    isLoading: false,
    results: [],
    value: '',
    shop_name: this.props.shop_name ? this.props.shop_name : '',
    cover_image: this.props.cover_image ? this.props.cover_image : '',
    address: this.props.address ? this.props.address : '',
    phone: this.props.phone ? this.props.phone : '',
    phone2: this.props.phone2 ? this.props.phone2 : '',
    description: this.props.description ? this.props.description : '',
    accept_card:
      !this.props.accept_card ||
      this.props.accept_card === false ||
      this.props.accept_card === 0
        ? false
        : true,
    list_cards: this.props.list_cards ? this.props.list_cards : [],
    list_images: this.props.list_images ? this.props.list_images : [],
    type_s: this.props.type_s ? this.props.type_s : '',
    lat: this.props.lat ? this.props.lat : '',
    lon: this.props.lon ? this.props.lon : '',
    service_type: this.props.service_type ? this.props.service_type : '',
    logo: this.props.logo ? this.props.logo : '',
    Mastercard: this.props.list_cards.find((card) => card === 'Mastercard')
      ? true
      : false,
    Visa: this.props.list_cards.find((card) => card === 'Visa') ? true : false,
    Bancomer: this.props.list_cards.find((card) => card === 'Bancomer')
      ? true
      : false,
    Banamex: this.props.list_cards.find((card) => card === 'Banamex')
      ? true
      : false,
    Santander: this.props.list_cards.find((card) => card === 'Santander')
      ? true
      : false,
    Banorte: this.props.list_cards.find((card) => card === 'Banorte')
      ? true
      : false,
    'American Express': this.props.list_cards.find(
      (card) => card === 'American Express'
    )
      ? true
      : false,
    'Banco Azteca': this.props.list_cards.find(
      (card) => card === 'Banco Azteca'
    )
      ? true
      : false,
    image_cover_preview: '',
    images_preview: [],
    show_edit: false,
    day_show_name: '',
    lun: this.props.LUN !== 'CLOSE' ? this.props.LUN : false,
    mar: this.props.MAR !== 'CLOSE' ? this.props.MAR : false,
    mie: this.props.MIE !== 'CLOSE' ? this.props.MIE : false,
    jue: this.props.JUE !== 'CLOSE' ? this.props.JUE : false,
    vie: this.props.VIE !== 'CLOSE' ? this.props.VIE : false,
    sab: this.props.SAB !== 'CLOSE' ? this.props.SAB : false,
    dom: this.props.DOM !== 'CLOSE' ? this.props.DOM : false,
    lun_hours: this.props.LUN !== 'CLOSE' ? this.props.LUN : 'CLOSE',
    mar_hours: this.props.MAR !== 'CLOSE' ? this.props.MAR : 'CLOSE',
    mie_hours: this.props.MIE !== 'CLOSE' ? this.props.MIE : 'CLOSE',
    jue_hours: this.props.JUE !== 'CLOSE' ? this.props.JUE : 'CLOSE',
    vie_hours: this.props.VIE !== 'CLOSE' ? this.props.VIE : 'CLOSE',
    sab_hours: this.props.SAB !== 'CLOSE' ? this.props.SAB : 'CLOSE',
    dom_hours: this.props.DOM !== 'CLOSE' ? this.props.DOM : 'CLOSE',
    modalOpen: false,
    shop_id: '',
    image_logo_preview: '',
    list_images_deleted: [],
    list_new_images: [],
    empty_fields: [],
  });

  state = this.initialState();

  onPreviewDrop = (files) => {
    this.setState({
      files: this.state.files.concat(files),
    });
  };

  refmarker = React.createRef();

  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable });
  };

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      this.setState({
        marker: marker.leafletElement.getLatLng(),
        lat: marker.leafletElement.getLatLng().lat,
        lon: marker.leafletElement.getLatLng().lng,
        new_latitude: marker.leafletElement.getLatLng().lat,
        new_longitude: marker.leafletElement.getLatLng().lng,
      });
    }
  };

  handleSearchChange = async (e, { value }) => {
    this.setState({ isLoading: true, value });

    let results = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${value}`
    ).then((res) => res.json());

    this.setState({
      isLoading: false,
      results: results.map((r) => ({
        title: r.display_name,
        childKey: r.place_id,
        location: { lat: r.lat, lng: r.lon },
      })),
    });
  };

  handleResultSelect = (e, { result }) => {
    this.setState({
      center: {
        lat: result.location.lat,
        lng: result.location.lng,
      },
      marker: {
        lat: result.location.lat,
        lng: result.location.lng,
      },
      lat: result.location.lat,
      lon: result.location.lng,
    });
  };

  handleResultSelectMaps = (place) => {
    if (!place.name) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.setState({
        center: {
          lat,
          lng,
        },
        marker: {
          lat,
          lng,
        },
        lat: lat,
        lon: lng,
        new_latitude: lat,
        new_longitude: lng,
        address: place.formatted_address,
      });
    } else {
      this.setState({
        address: place.name,
      });
    }
  };

  update_information_shop = (e, type) => {
    const { get_sub_services } = this.props;
    switch (type) {
      case 'name_shop':
        this.setState({
          shop_name: e.target.value,
        });
        break;
      case 'address':
        this.setState({
          address: e.target.value,
        });
        break;
      case 'phone':
        this.setState({
          phone: e.target.value,
        });
        break;
      case 'phone2':
        this.setState({
          phone2: e.target.value,
        });
        break;
      case 'description':
        this.setState({
          description: e.target.value,
        });
        break;
      case 'accept_card':
        console.log('esta es ', e);
        this.setState({
          accept_card: e,
        });
        break;
      case 'list_cards':
        let list_card = this.state.list_cards;
        if (e.target.checked) {
          list_card.push(e.target.name);
        } else {
          list_card.splice(list_card.indexOf(e.target.name), 1);
        }
        this.setState({
          list_cards: list_card,
          [e.target.name]: e.target.checked,
        });
        break;
      case 'service_type':
        get_sub_services(e.value);
        this.setState({
          type_s: e.value,
        });
        break;
      case 'subservice_type':
        this.setState({
          service_type: e.value,
        });
        break;
      default:
        break;
    }
  };

  check_day = (e, day) => {
    switch (day) {
      case 'lunes':
        this.setState({
          lun: e.target.checked,
          day_show_name: e.target.checked ? 'LUN' : '',
          show_edit: false,
          lun_hours: !e.target.checked ? 'CLOSE' : this.state.lun_hours,
        });
        break;
      case 'martes':
        this.setState({
          mar: e.target.checked,
          day_show_name: e.target.checked ? 'MAR' : '',
          show_edit: false,
          mar_hours: !e.target.checked ? 'CLOSE' : this.state.mar_hours,
        });
        break;
      case 'miercoles':
        this.setState({
          mie: e.target.checked,
          day_show_name: e.target.checked ? 'MIE' : '',
          show_edit: false,
          mie_hours: !e.target.checked ? 'CLOSE' : this.state.mie_hours,
        });
        break;
      case 'jueves':
        this.setState({
          jue: e.target.checked,
          day_show_name: e.target.checked ? 'JUE' : '',
          show_edit: false,
          jue_hours: !e.target.checked ? 'CLOSE' : this.state.jue_hours,
        });
        break;
      case 'viernes':
        this.setState({
          vie: e.target.checked,
          day_show_name: e.target.checked ? 'VIE' : '',
          show_edit: false,
          vie_hours: !e.target.checked ? 'CLOSE' : this.state.vie_hours,
        });
        break;
      case 'sabado':
        this.setState({
          sab: e.target.checked,
          day_show_name: e.target.checked ? 'SAB' : '',
          show_edit: false,
          sab_hours: !e.target.checked ? 'CLOSE' : this.state.sab_hours,
        });
        break;
      case 'domingo':
        this.setState({
          dom: e.target.checked,
          day_show_name: e.target.checked ? 'DOM' : '',
          show_edit: false,
          dom_hours: !e.target.checked ? 'CLOSE' : this.state.dom_hours,
        });
        break;
      default:
        break;
    }
  };

  change_state_image_cover = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          image_cover_preview: reader.result,
        });
      };

      reader.readAsDataURL(file);

      const formData = new FormData();

      formData.append('file', file);

      this.props.upload_cover(formData, this.props.token).then((response) => {
        if (response.type === 'UPLOAD_IMAGE_COVER_SUCCESS') {
          const {
            payload: {
              data: { result },
            },
          } = response;
          this.setState({ cover_image: result });
        }
      });
    } else {
      alert('Error al selecionar la imagen');
    }
  };

  change_state_image_logo = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          image_logo_preview: reader.result,
        });
      };

      reader.readAsDataURL(file);

      const formData = new FormData();

      formData.append('file', file);

      this.props.upload_logo(formData, this.props.token).then((response) => {
        if (response.type === 'UPLOAD_IMAGE_LOGO_SUCCESS') {
          const {
            payload: {
              data: { result },
            },
          } = response;
          this.setState({ logo: result });
        }
      });
    } else {
      alert('Error al selecionar la imagen');
    }
  };

  change_state_images = (files) => {
    return files.forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.state.images_preview.push(reader.result);
        this.setState({
          images_preview: this.state.images_preview,
        });
      };

      reader.readAsDataURL(file);

      const formData = new FormData();

      formData.append('file', file);

      this.props.upload_images(formData, this.props.token).then((response) => {
        if (response.type === 'UPLOAD_IMAGES_SUCCESS') {
          const {
            payload: {
              data: { result },
            },
          } = response;
          this.state.list_images.push(result);
          this.state.list_new_images.push(result);
          this.setState({
            list_images: this.state.list_images,
            list_new_images: this.state.list_new_images,
          });
        }
      });
    });
  };

  delete_images = (img_data) => {
    const index = this.state.list_images.indexOf(img_data);
    const exist_image = this.state.list_images.find((im) => im === img_data);
    this.state.list_images.splice(index, 1);

    if (exist_image) {
      this.state.list_images_deleted.push(img_data);
    }

    this.setState({
      list_images: this.state.list_images,
      list_images_deleted: this.state.list_images_deleted,
    });
    return;
  };

  showEdit = (show, day) => {
    this.setState({
      show_edit: show,
      day_show_name: show ? day : this.state.day_show_name,
    });
  };

  saveShop = (save_active) => {
    const {
      shop_name,
      address,
      phone,
      phone2,
      description,
      cover_image,
      logo,
      accept_card,
      list_cards,
      list_images,
      type_s,
      lat,
      lon,
      service_type,
      lun_hours,
      mar_hours,
      mie_hours,
      jue_hours,
      vie_hours,
      sab_hours,
      dom_hours,
      list_images_deleted,
      list_new_images,
    } = this.state;
    let {
      token,
      save_shop,
      delete_images_server,
      match: {
        params: { shop_id },
      },
    } = this.props;

    const validate_fields = {
      shop_name,
      address,
      description,
      type_s,
      service_type,
      cover_image,
      logo,
    };

    if (
      shop_name &&
      cover_image &&
      address &&
      description &&
      type_s &&
      service_type &&
      logo
    ) {
      console.log(
        'que es=',
        accept_card === 1 || accept_card === true ? true : false,
        '999oooo9999',
        shop_id ? list_new_images : list_images,
        '<<<<'
      );
      save_shop(
        token,
        {
          shop_name,
          address,
          phone,
          phone2,
          description,
          cover_image,
          logo,
          accept_card: accept_card === 1 || accept_card === true ? true : false,
          list_cards,
          list_images: shop_id ? list_new_images : list_images,
          service_type_id: type_s,
          lat,
          lon,
          sub_service_type_id: service_type,
          shop_schedules: [
            lun_hours,
            mar_hours,
            mie_hours,
            jue_hours,
            vie_hours,
            sab_hours,
            dom_hours,
          ],
        },
        shop_id
      ).then(async (response) => {
        if (response.type === 'SAVE_PROPERTY_SUCCESS') {
          console.log(
            list_images_deleted,
            'estas son las imagenes para eliminar'
          );
          await Promise.all([
            list_images_deleted.forEach((url) => {
              delete_images_server(url, shop_id, token);
            }),
          ]);
          const initialState = this.initialState();
          console.log(response.payload.data.shop_id, shop_id);
          if (response.payload.data.shop_id) {
            initialState.shop_id = response.payload.data.shop_id;
            shop_id = response.payload.data.shop_id;
          } else {
            initialState.shop_id = shop_id;
          }

          if (save_active) {
            console.log(response.payload, 'tyuiokjnojn');
            this.props.history.push(`/shop/${shop_id}/billing`);
          } else {
            initialState.modalOpen = true;
          }
          this.setState(initialState);
        }
      });
    } else {
      const empty_fields = [];
      for (let key in validate_fields) {
        empty_fields.push(fields_translate[key]);
      }
      this.setState({
        empty_fields,
      });
    }
  };

  edit_hours = (hour, day, am_pm) => {
    switch (day) {
      case 'LUN':
        const hour_edit_lun = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_lun =
          am_pm === 'am'
            ? `${hour_edit_lun}${
                this.state.lun_hours === 'CLOSE' ||
                this.state.lun_hours.length === 19
                  ? this.state.lun_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.lun_hours === 'CLOSE' ||
                this.state.lun_hours.length === 8 ||
                this.state.lun_hours.length === 19
                  ? this.state.lun_hours.slice(0, 8)
                  : ''
              }${hour_edit_lun}`;
        this.setState({
          lun_hours: compose_hour_lun,
        });
        break;
      case 'MAR':
        const hour_edit_mar = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_mar =
          am_pm === 'am'
            ? `${hour_edit_mar}${
                this.state.mar_hours === 'CLOSE' ||
                this.state.mar_hours.length === 19
                  ? this.state.mar_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.mar_hours === 'CLOSE' ||
                this.state.mar_hours.length === 8 ||
                this.state.mar_hours.length === 19
                  ? this.state.mar_hours.slice(0, 8)
                  : ''
              }${hour_edit_mar}`;
        this.setState({
          mar_hours: compose_hour_mar,
        });
        break;
      case 'MIE':
        const hour_edit_mie = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_mie =
          am_pm === 'am'
            ? `${hour_edit_mie}${
                this.state.mie_hours === 'CLOSE' ||
                this.state.mie_hours.length === 19
                  ? this.state.mie_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.mie_hours === 'CLOSE' ||
                this.state.mie_hours.length === 8 ||
                this.state.mie_hours.length === 19
                  ? this.state.mie_hours.slice(0, 8)
                  : ''
              }${hour_edit_mie}`;
        this.setState({
          mie_hours: compose_hour_mie,
        });
        break;
      case 'JUE':
        const hour_edit_jue = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_jue =
          am_pm === 'am'
            ? `${hour_edit_jue}${
                this.state.jue_hours === 'CLOSE' ||
                this.state.jue_hours.length === 19
                  ? this.state.jue_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.jue_hours === 'CLOSE' ||
                this.state.jue_hours.length === 8 ||
                this.state.jue_hours.length === 19
                  ? this.state.jue_hours.slice(0, 8)
                  : ''
              }${hour_edit_jue}`;
        this.setState({
          jue_hours: compose_hour_jue,
        });
        break;
      case 'VIE':
        const hour_edit_vie = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_vie =
          am_pm === 'am'
            ? `${hour_edit_vie}${
                this.state.vie_hours === 'CLOSE' ||
                this.state.vie_hours.length === 19
                  ? this.state.vie_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.vie_hours === 'CLOSE' ||
                this.state.vie_hours.length === 8 ||
                this.state.vie_hours.length === 19
                  ? this.state.vie_hours.slice(0, 8)
                  : ''
              }${hour_edit_vie}`;
        this.setState({
          vie_hours: compose_hour_vie,
        });
        break;
      case 'SAB':
        const hour_edit_sab = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_sab =
          am_pm === 'am'
            ? `${hour_edit_sab}${
                this.state.sab_hours === 'CLOSE' ||
                this.state.sab_hours.length === 19
                  ? this.state.sab_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.sab_hours === 'CLOSE' ||
                this.state.sab_hours.length === 8 ||
                this.state.sab_hours.length === 19
                  ? this.state.sab_hours.slice(0, 8)
                  : ''
              }${hour_edit_sab}`;
        this.setState({
          sab_hours: compose_hour_sab,
        });
        break;
      case 'DOM':
        const hour_edit_dom = am_pm === 'am' ? `${hour} am` : ` - ${hour} pm`;
        const compose_hour_dom =
          am_pm === 'am'
            ? `${hour_edit_dom}${
                this.state.dom_hours === 'CLOSE' ||
                this.state.dom_hours.length === 19
                  ? this.state.dom_hours.slice(8, 19)
                  : ''
              }`
            : `${
                this.state.dom_hours === 'CLOSE' ||
                this.state.dom_hours.length === 8 ||
                this.state.dom_hours.length === 19
                  ? this.state.dom_hours.slice(0, 8)
                  : ''
              }${hour_edit_dom}`;
        this.setState({
          dom_hours: compose_hour_dom,
        });
        break;

      default:
        break;
    }
  };

  componentWillMount = () => {
    const {
      match: {
        path,
        params: { shop_id },
      },
      reset_state_shop,
      get_sub_services,
    } = this.props;
    if (path === '/new-shop') {
      reset_state_shop();
      setTimeout(() => {
        const new_state = this.initialState();
        this.setState(new_state, () => {
          console.log(navigator.geolocation, '===?');
          if (navigator.geolocation) {
            console.log('aqui amigo');
            navigator.geolocation.getCurrentPosition(
              ({ coords: { latitude, longitude } }) => {
                console.log('esta pàsando?');
                this.setState({
                  new_latitude: latitude,
                  new_longitude: longitude,
                  new_shop_location: true,
                });
              },
              (error) => console.log(error),
              {
                timeout: Infinity,
                maximumAge: Infinity,
              }
            );
          }
        });
      }, 2);
    } else {
      this.props.get_shop(shop_id).then((response) => {
        if (response.type === 'GET_PROPERTY_SUCCESS') {
          const {
            payload: {
              data: { cover_image, images, logo, service_type_id },
            },
          } = response;
          get_sub_services(service_type_id).then((r) => {
            this.setState(this.initialState());
            this.setState({
              image_cover_preview: cover_image,
              cover_image: cover_image,
              list_images: images,
              image_logo_preview: logo,
              logo: logo,
              shop_id: shop_id,
            });
          });
        }
      });
    }
  };

  close_emptyFields = () => this.setState({ empty_fields: [] });

  render() {
    let {
      shop_name,
      address,
      phone,
      phone2,
      description,
      accept_card,
      list_images = [],
      type_s,
      service_type,
      cover_image,
      logo,
      Mastercard,
      Visa,
      Bancomer,
      Banamex,
      Santander,
      Banorte,
      'American Express': American_Express,
      'Banco Azteca': Banco_Azteca,
      image_cover_preview,
      image_logo_preview,
      show_edit,
      lun,
      mar,
      mie,
      jue,
      vie,
      sab,
      dom,
      day_show_name,
      modalOpen,
      shop_id,
      lun_hours,
      mar_hours,
      mie_hours,
      jue_hours,
      vie_hours,
      sab_hours,
      dom_hours,
      new_latitude,
      new_longitude,
      new_shop_location,
      empty_fields = [],
    } = this.state;
    const {
      status,
      services_list,
      sub_service_list,
      services_types = [],
      sub_service_types = [],
    } = this.props;
    const position = new_shop_location
      ? [new_latitude, new_longitude]
      : [this.state.center.lat, this.state.center.lng];
    const markerPosition = new_shop_location
      ? [new_latitude, new_longitude]
      : [this.state.marker.lat, this.state.marker.lng];
    console.log(this.props);
    console.log(this.state);

    const split_logo = logo.split('/upload/');
    logo = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_logo[1]}`;
    const split_cover = cover_image.split('/upload/');
    cover_image = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_cover[1]}`;

    if (image_logo_preview && image_logo_preview.split('http').length > 1) {
      const split_image_logo_preview = image_logo_preview.split('/upload/');
      image_logo_preview = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_image_logo_preview[1]}`;
    }

    if (image_cover_preview && image_cover_preview.split('http').length > 1) {
      const split_cover_preview = image_cover_preview.split('/upload/');
      image_cover_preview = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_cover_preview[1]}`;
    }

    return (
      <div>
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 800 }}>
            <Segment stacked>
              <Header
                as="h2"
                content={this.props.title_service}
                inverted
                style={{
                  textAlign: 'left',
                  fontWeight: 'normal',
                  marginBottom: 0,
                  color: '#15bfc2',
                  fontFamily: 'Montserrat',
                }}
              />
              <Divider />
              <Form size="large">
                <Form.Input
                  fluid
                  icon="shop"
                  iconPosition="left"
                  placeholder="Nombre del servicio"
                  value={shop_name}
                  onChange={(e) => this.update_information_shop(e, 'name_shop')}
                  required
                />
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    icon="phone"
                    iconPosition="left"
                    placeholder="Telefono 1"
                    type="number"
                    value={phone}
                    onChange={(e) => this.update_information_shop(e, 'phone')}
                  />

                  <Form.Input
                    fluid
                    icon="phone"
                    iconPosition="left"
                    placeholder="Telefono 2"
                    type="number"
                    value={phone2}
                    onChange={(e) => this.update_information_shop(e, 'phone2')}
                  />
                </Form.Group>
                {/* <Form.Input
                  required
                  fluid
                  icon="location arrow"
                  iconPosition="left"
                  placeholder="Dirección"
                  value={address}
                  onChange={e => this.update_information_shop(e, 'address')}
                /> */}
                <Autocomplete
                  style={{ width: '100%' }}
                  onPlaceSelected={this.handleResultSelectMaps}
                  types={['address']}
                  value={address}
                  placeholder="Introduce la dirección"
                  onChange={(e) => this.update_information_shop(e, 'address')}
                />
                <Map
                  style={{ width: '100%', margin: '1rem 0rem' }}
                  center={position}
                  zoom={this.state.zoom}
                  zoomControl
                  maxZoom={19}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker
                    draggable={this.state.draggable}
                    onDragend={this.updatePosition}
                    position={markerPosition}
                    ref={this.refmarker}
                  >
                    <Popup minWidth={90}>
                      <span onClick={this.toggleDraggable}>
                        {this.state.draggable ? 'DRAG MARKER' : 'MARKER FIXED'}
                      </span>
                    </Popup>
                  </Marker>
                </Map>
                <TextArea
                  required
                  placeholder="Descripción del servicio"
                  style={{ minHeight: 100, marginBottom: '0.5rem' }}
                  value={description}
                  onChange={(e) =>
                    this.update_information_shop(e, 'description')
                  }
                />

                <Form.Group inline>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Lunes"
                      name="LUN"
                      checked={lun}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'lunes')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {lun
                        ? lun_hours !== 'CLOSE'
                          ? lun_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {lun && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'LUN' ? false : true,
                            'LUN'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'LUN'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Martes"
                      name="MAR"
                      checked={mar}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'martes')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {mar
                        ? mar_hours !== 'CLOSE'
                          ? mar_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {mar && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'MAR' ? false : true,
                            'MAR'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'MAR'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Miercoles"
                      name="MIE"
                      checked={mie}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'miercoles')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {mie
                        ? mie_hours !== 'CLOSE'
                          ? mie_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {mie && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'MIE' ? false : true,
                            'MIE'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'MIE'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Jueves"
                      name="JUE"
                      checked={jue}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'jueves')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {jue
                        ? jue_hours !== 'CLOSE'
                          ? jue_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {jue && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'JUE' ? false : true,
                            'JUE'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'JUE'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Viernes"
                      name="VIER"
                      checked={vie}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'viernes')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {vie
                        ? vie_hours !== 'CLOSE'
                          ? vie_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {vie && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'VIE' ? false : true,
                            'VIE'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'VIE'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Sabado"
                      name="SAB"
                      checked={sab}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'sabado')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {sab
                        ? sab_hours !== 'CLOSE'
                          ? sab_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {sab && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'SAB' ? false : true,
                            'SAB'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'SAB'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                  <div
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    <Form.Field
                      label="Domingo"
                      name="DOM"
                      checked={dom}
                      control="input"
                      type="checkbox"
                      onChange={(e) => this.check_day(e, 'domingo')}
                    />
                    <span style={{ fontSize: '11px' }}>
                      {dom
                        ? dom_hours !== 'CLOSE'
                          ? dom_hours
                          : 'Cerrado'
                        : 'Cerrado'}
                    </span>
                    {dom && (
                      <div
                        style={{
                          border: '1px solid gray',
                          color: 'gray',
                          borderRadius: '5px',
                          padding: '0.15rem',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          this.showEdit(
                            show_edit && day_show_name === 'DOM' ? false : true,
                            'DOM'
                          )
                        }
                      >
                        {show_edit && day_show_name === 'DOM'
                          ? 'Cerrar'
                          : 'Horario'}
                      </div>
                    )}
                  </div>
                </Form.Group>
                {show_edit && day_show_name && (
                  <Form.Group>
                    <div
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Message info compact size="mini">
                        <p>Agregar 0 antes si solo es un número</p>
                        <p>Ejemplo: 01:30</p>
                      </Message>
                      <div
                        style={{
                          textAlign: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                        className="equal width fields"
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Label pointing="below">am</Label>
                          <TimeInput
                            initTime={
                              this.state[
                                `${day_show_name.toLowerCase()}_hours`
                              ] === 'CLOSE' ||
                              this.state[`${day_show_name.toLowerCase()}_hours`]
                                .length === 19
                                ? this.state[
                                    `${day_show_name.toLowerCase()}_hours`
                                  ] === 'CLOSE'
                                  ? ''
                                  : this.state[
                                      `${day_show_name.toLowerCase()}_hours`
                                    ].slice(0, 5)
                                : ''
                            }
                            ref="TimeInputWrapper"
                            className="form-control"
                            onTimeChange={(value) =>
                              this.edit_hours(value, day_show_name, 'am')
                            }
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Label pointing="below">pm</Label>
                          <TimeInput
                            initTime={
                              this.state[
                                `${day_show_name.toLowerCase()}_hours`
                              ] === 'CLOSE' ||
                              this.state[`${day_show_name.toLowerCase()}_hours`]
                                .length === 8 ||
                              this.state[`${day_show_name.toLowerCase()}_hours`]
                                .length === 19
                                ? this.state[
                                    `${day_show_name.toLowerCase()}_hours`
                                  ].slice(11, 16)
                                : ''
                            }
                            className="form-control"
                            onTimeChange={(value) =>
                              this.edit_hours(value, day_show_name, 'pm')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                )}

                <Form.Group inline>
                  <label>¿Aceptas tarjeta?</label>
                  <Form.Field
                    label="Si"
                    control="input"
                    type="radio"
                    checked={accept_card}
                    value={accept_card}
                    onChange={(e) =>
                      this.update_information_shop(true, 'accept_card')
                    }
                    name="htmlRadios"
                  />
                  <Form.Field
                    label="No"
                    control="input"
                    type="radio"
                    checked={!accept_card}
                    value={!accept_card}
                    name="htmlRadios"
                    onChange={(e) =>
                      this.update_information_shop(false, 'accept_card')
                    }
                  />
                </Form.Group>

                {accept_card && (
                  <Form.Group inline>
                    <Form.Field
                      label="Mastercard"
                      name="Mastercard"
                      checked={Mastercard}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="Visa"
                      name="Visa"
                      checked={Visa}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="Bancomer"
                      name="Bancomer"
                      checked={Bancomer}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="Banamex"
                      name="Banamex"
                      checked={Banamex}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="Santander"
                      name="Santander"
                      checked={Santander}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="Banorte"
                      name="Banorte"
                      checked={Banorte}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="American Express"
                      name="American Express"
                      checked={American_Express}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                    <Form.Field
                      label="Banco Azteca"
                      name="Banco Azteca"
                      checked={Banco_Azteca}
                      control="input"
                      type="checkbox"
                      onChange={(e) =>
                        this.update_information_shop(e, 'list_cards')
                      }
                    />
                  </Form.Group>
                )}

                <Form.Group style={{ textAlign: 'center' }} widths="equal">
                  <Form.Select
                    fluid
                    options={services_list.map(
                      ({ service_type_id, service_name }, key) => {
                        return {
                          value: service_type_id,
                          text: services_types[service_name],
                          key: service_type_id,
                        };
                      }
                    )}
                    placeholder="Servicio"
                    value={type_s}
                    onChange={(e, data) =>
                      this.update_information_shop(data, 'service_type')
                    }
                  />
                  <Form.Select
                    fluid
                    options={sub_service_list.map(
                      ({ sub_service_type_id, sub_service_name }, key) => {
                        return {
                          value: sub_service_type_id,
                          text: sub_service_types[sub_service_name],
                          key: sub_service_type_id,
                        };
                      }
                    )}
                    placeholder="Tipo de servicio"
                    value={service_type}
                    onChange={(e, data) =>
                      this.update_information_shop(data, 'subservice_type')
                    }
                  />
                </Form.Group>

                <Form.Input
                  fluid
                  iconPosition="left"
                  label="Subir logo"
                  type="file"
                  onChange={this.change_state_image_logo}
                />
                <Grid
                  style={{ marginBottom: '0.5rem' }}
                  textAlign="center"
                  columns={2}
                >
                  <Grid.Column>
                    {(logo || image_logo_preview) && (
                      <Image src={image_logo_preview} />
                    )}
                  </Grid.Column>
                </Grid>
                <Form.Input
                  fluid
                  iconPosition="left"
                  label="Subir foto de portada"
                  type="file"
                  onChange={this.change_state_image_cover}
                />
                <Grid
                  style={{ marginBottom: '0.5rem' }}
                  textAlign="center"
                  columns={2}
                >
                  <Grid.Column>
                    {(cover_image || image_cover_preview) && (
                      <Image src={image_cover_preview} />
                    )}
                  </Grid.Column>
                </Grid>
                <Dropzone onDrop={this.change_state_images} accept="image/*">
                  {({ getRootProps, getInputProps }) => (
                    <div className="drop-zone" {...getRootProps()}>
                      <input {...getInputProps()} />
                      Da click o arrastras las imagenes aquí
                    </div>
                  )}
                </Dropzone>
                <Grid style={{ marginBottom: '0.5rem' }} doubling columns={5}>
                  {list_images.map((i, k) => {
                    console.log(i, k, 'es este?');
                    let show_i;
                    if (i) {
                      const split_i = i.split('/upload/');
                      show_i = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_i[1]}`;
                    } else if (i && i === undefined) {
                      const split_i = i.split('/upload/');
                      show_i = `https://res.cloudinary.com/h27hacklab/image/upload/f_auto,q_auto/${split_i[1]}`;
                    }

                    return (
                      <Grid.Column key={k}>
                        <Image src={show_i} key={k} />
                        <div
                          style={{
                            marginTop: '0.2rem',
                            border: '1px solid red',
                            padding: '0.5rem',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            color: 'red',
                          }}
                          onClick={() => this.delete_images(i)}
                        >
                          Eliminar
                        </div>
                      </Grid.Column>
                    );
                  })}
                </Grid>
                {/* <Message>
                  <Message.Header>Información de busqueda</Message.Header>
                  <p>
                    Si la ubicacion que buscas no aparece, intenta buscar la
                    ubicación más cercana y arrastra el marcador del mapa a la
                    ubicación que deseas.
                  </p>
                </Message> */}
                {/* <Search
                  input={{
                    icon: 'search',
                    iconPosition: 'left',
                    className: 'searcher-for-map'
                  }}
                  className={modalOpen ? '' : 'index-buscador'}
                  fluid
                  loading={this.state.isLoading}
                  onResultSelect={this.handleResultSelect}
                  onSearchChange={this.handleSearchChange}
                  value={this.state.value}
                  results={this.state.results}
                /> */}
                <Button.Group style={{ marginTop: '0.5rem' }} size="large">
                  <Button
                    content="Guardar"
                    icon="save"
                    color="blue"
                    labelPosition="right"
                    onClick={() => this.saveShop()}
                  />
                  {status === 0 && (
                    <Button
                      content="Activar"
                      onClick={() => this.saveShop(true)}
                      icon="shop"
                      color="teal"
                      labelPosition="right"
                    />
                  )}
                </Button.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>

        <Modal
          closeOnDimmerClick={false}
          open={modalOpen}
          basic
          size="small"
          className="index-modal"
        >
          <Header icon="browser" content="Servicio guardado" />
          <Modal.Content>
            <h3>El servicio ha sido guardado.</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="green"
              onClick={() => this.props.history.push(`/services`)}
              inverted
            >
              <Icon name="checkmark" /> Cerrar
            </Button>
            {status === 0 && (
              <Button
                color="green"
                onClick={() =>
                  this.props.history.push(`/shop/${shop_id}/billing`)
                }
                inverted
              >
                <Icon name="shop" /> Activar
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Modal
          size="mini"
          open={empty_fields.length > 0 ? true : false}
          onClose={this.close_emptyFields}
          style={{ textAlign: 'center' }}
        >
          <Modal.Header>Los siguientes campos estan incompletos</Modal.Header>
          <Modal.Content>
            <List>
              {empty_fields.map((field, key) => (
                <List.Item key={key}>{field}</List.Item>
              ))}
            </List>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({
  loginReducer: { token },
  generalReducer: { services_types, sub_service_types },
  shopReducer: {
    shop_name,
    address,
    phone,
    phone2,
    description,
    accept_card,
    list_cards,
    list_images,
    type_s,
    lat,
    lon,
    service_type,
    error_save,
    LUN,
    MAR,
    MIE,
    JUE,
    VIE,
    SAB,
    DOM,
    status,
    services: services_list,
    sub_service: sub_service_list,
  },
}) => ({
  shop_name,
  address,
  phone,
  phone2,
  description,
  accept_card,
  list_cards,
  list_images,
  type_s,
  lat,
  lon,
  service_type,
  token,
  error_save,
  LUN,
  MAR,
  MIE,
  JUE,
  VIE,
  SAB,
  DOM,
  status,
  services_list,
  sub_service_list,
  services_types,
  sub_service_types,
});

const mapDispatchToProps = {
  upload_cover,
  upload_logo,
  upload_images,
  save_shop,
  get_shop,
  reset_state_shop,
  delete_images_server,
  get_list_services,
  get_sub_services,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BodyForm)
);

//https://github.com/smeijer/leaflet-geosearch
//https://www.igismap.com/switching-between-google-maps-and-openstreetmap-in-react-native/

// bancopel
// joel
// 4169160330988040
