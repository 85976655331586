import React, { Component, Fragment } from 'react';
import {
  Switch,
  Redirect,
  Route,
  withRouter,
  BrowserRouter
} from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';
import Menu from './components/menu';
import Login from './components/login';
import Services from './components/services';
import ListServices from './components/list-services';
import Shop from './components/shop';
import Register from './components/register';
import Profile from './components/profile';
import { NewShop, EditShop } from './components/new-shop';
import ListOffers from './components/offers';
import Pay from './components/pay';
import VerificationCodeActivation from './components/register/verify-acount';
import Pages from './components/pages/index';
import InitialPage from './components/initial';
import { set_location } from './redux/actions/General';

class App extends Component {
  reinsert_position = () => {
    const { set_location } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          set_location(latitude, longitude);
        },
        error => console.log(error)
      );
    } else {
    }
  };

  componentDidMount = () => {
    const { set_location } = this.props;
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        ({ coords: { latitude, longitude } }) => {
          console.log(latitude, longitude, '-.-.-');
          set_location(latitude, longitude);
        },
        err => console.log(err)
      );
    }
  };

  render() {
    const { token } = this.props;

    return (
      <BrowserRouter>
        <Switch>
          <Fragment>
            <Menu />
            <Route exact path="/" component={InitialPage} />
            <Route
              exact
              path="/login"
              render={() => (token ? <Redirect to="/services" /> : <Login />)}
            />
            <Route exact path="/register" component={Register} />
            <Route
              exact
              path="/services"
              render={() => <Services key={Date.now()} />}
            />
            <Route
              exact
              path="/list-sevice/:service"
              component={ListServices}
            />
            <Route exact path="/shop/:shop_id" component={Shop} />
            <Route exact path="/profile" component={Profile} />
            <Route
              exact
              path="/new-shop"
              render={() =>
                token ? <NewShop key={Date.now()} /> : <Redirect to="/login" />
              }
            />
            <Route
              exact
              path="/edit-shop/:shop_id"
              render={() => <EditShop key={Date.now()} />}
            />
            <Route exact path="/offers" component={ListOffers} />
            <Route exact path="/shop/:shop_id/billing" component={Pay} />
            <Route
              exact
              path="/verification_code"
              component={VerificationCodeActivation}
            />
            <Route exact path="/:shop_id/page" component={Pages} />
          </Fragment>
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({
  generalReducer: { my_lat, my_lon },
  loginReducer: { token, login_error }
}) => ({
  token,
  login_error
});

const mapDispatchToProps = {
  set_location
};

const o = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(o);
